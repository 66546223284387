import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import LeftMenu from "./LeftMenu";
import NewProductPerformanceReport from "../NewProductPerformanceReport";
import ProductPerformanceHistory from "../ProductPerformanceHistory";
import "./ReportsReviews.css";

const ReportsReviews = () => {
  const { type } = useParams();
  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <LeftMenu />
      </Grid>
      <Grid item md={9}>
        {(type === "product-performance" && (
          <ProductPerformanceHistory story="tda" />
        )) ||
          (type === "new-product-performance" && (
            <NewProductPerformanceReport story="tda" dataSet="custom" />
          ))}
      </Grid>
    </Grid>
  );
};

export default ReportsReviews;
