import React from "react";
import PropTypes from "prop-types";
import { Grid, Card, CardContent, Typography } from "@mui/material";

const Overview = props => {
  const { data, nl, meta, theme } = props;
  return (
    <Card raised sx={{ height: 1, width: 1, bgcolor: theme.secondary }}>
      <CardContent sx={{ height: 110, bgcolor: theme.primary }}>
        <Grid container>
          <Grid item md={3}>
            <Typography variant="subtitle2" color="white" gutterBottom>
              Value Sales
            </Typography>
            <Typography variant="h5" color="white">
              {meta.value_metric}
              {data["Value Sales"].t2.toFixed(0)}
            </Typography>
            <Typography variant="dashboardBody">
              {data["Value Sales"]["% change"]}% vs YA
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle2" color="white" gutterBottom>
              Volume Sales ({meta.volume_metric})
            </Typography>
            <Typography variant="h5" color="white">
              {data["Volume Sales"].t2}
            </Typography>
            <Typography variant="dashboardBody" color="white">
              {data["Volume Sales"]["% change"]}% vs YA
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle2" color="white" gutterBottom>
              Basket Incidence
            </Typography>
            <Typography variant="h5" color="white">
              {data["Basket Incidence"].t2}%
            </Typography>
            <Typography variant="dashboardBody">
              {data["Basket Incidence"].diff} pts vs YA
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle2" color="white" gutterBottom>
              Price per Volume Unit
            </Typography>
            <Typography variant="h5" color="white">
              {meta.value_metric}
              {data["Price per Volume Unit"].t2.toFixed(2)}
            </Typography>
            <Typography variant="dashboardBody">
              {data["Price per Volume Unit"]["% change"]}% vs YA
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Typography variant="subtitle2" color="white" gutterBottom>
          INSIGHTS
        </Typography>
        {nl.map((i, k) => (
          <Typography
            key={i}
            variant="dashboardBody"
            gutterBottom={k !== nl.length - 1}
          >
            {i}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};

Overview.propTypes = {
  data: PropTypes.shape(),
  nl: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape(),
  theme: PropTypes.shape()
};

Overview.defaultProps = {
  data: {},
  nl: [],
  meta: {},
  theme: {}
};

export default Overview;
