import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Card, CardHeader, Typography } from "@mui/material";
import { connect } from "react-redux";
import { getHours } from "date-fns";
import DataUpdate from "./DataUpdate";

const Home = props => {
  const { date, client } = props;
  const getGreeting = () => {
    const hour = getHours(new Date());
    if (hour >= 2 && hour < 12) {
      return "Good Morning";
    }
    if (hour >= 12 && hour < 18) {
      return "Good Afternoon";
    }
    return "Good Evening";
  };
  return (
    <Card sx={{ p: 2.5, width: 1 }}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item md={5}>
          <CardHeader
            titleTypographyProps={{ variant: "h2", gutterBottom: true }}
            title={getGreeting()}
            subheader={
              <Fragment>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="primary.dark"
                >
                  We are here to simplify the understanding of business
                  performance.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Interrodata intelligently analyses transactional data to
                  generate meaningful reports about what happened, and why.
                </Typography>
              </Fragment>
            }
          />
        </Grid>
        <Grid item md={4} sx={{ p: 2 }}>
          <DataUpdate date={date} client={client} />
        </Grid>
      </Grid>
    </Card>
  );
};

Home.propTypes = {
  date: PropTypes.shape(),
  client: PropTypes.string
};

Home.defaultProps = {
  date: {},
  client: ""
};

const mapStateToProps = state => ({
  date: state.data.date,
  client: state.user.user.client
});

export default connect(mapStateToProps, null)(Home);
