import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#009dbc",
      dark: "#009cad"
    },
    secondary: {
      main: "#000074",
      dark: "#000034"
    },
    primaryDark: {
      main: "#009cad",
      light: "#009dbc",
      contrastText: "#fff"
    },
    secondaryDark: {
      main: "#000034",
      light: "#000074",
      contrastText: "#fff"
    },
    orange: {
      main: "#ef4129",
      dark: "#c5351f",
      contrastText: "#fff"
    },
    yellow: {
      main: "#faa61a",
      dark: "#ce8a14",
      contrastText: "#fff"
    },
    positive: {
      main: "#62c531"
    },
    negative: {
      main: "#e23636"
    },
    neutral: {
      main: "#f5a600"
    },
    disabled: {
      main: "#ada8a8",
      contrastText: "#fff"
    }
  },
  typography: {
    h1: {
      fontWeight: "bold",
      color: "#000074",
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em"
    },
    h2: {
      fontWeight: "bold",
      color: "#000074",
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em"
    },
    h3: {
      fontWeight: "bold",
      color: "#009cad",
      fontSize: "2.125rem",
      textTransform: "uppercase"
    },
    h4: {
      fontWeight: "bold",
      color: "#000074",
      fontSize: "1.125rem",
      lineHeight: 1.3
    },
    h5: {
      fontWeight: 500,
      color: "#000034"
    },
    h6: {
      fontWeight: 500,
      color: "#ef4129",
      fontSize: "1rem",
      lineHeight: 1.5
    },
    subtitle1: {
      fontWeight: 500,
      color: "#3c3838",
      lineHeight: 1.3
    },
    subtitle2: {
      color: "#272525"
    },
    body2: {
      color: "#3c3838"
    },
    h7: {
      fontWeight: 300,
      color: "#8f8b8b",
      fontSize: 12,
      lineHeight: 1.3
    },
    subtitle3: {
      fontWeight: 500,
      color: "#565151",
      fontSize: 12
    },
    dashboardTitle: {
      color: "#000034",
      fontSize: "1.2rem",
      lineHeight: 1.2
    },
    dashboardBody: {
      color: "#ffffff",
      fontSize: 12
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h7: "h6",
          subtitle3: "h6",
          dashboardTitle: "h6",
          dashboardBody: "p"
        }
      }
    }
  }
});

export default theme;
