import React from "react";
import PropTypes from "prop-types";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom } from "@visx/axis";

const Waterfall = props => {
  const { data, xVar, yVar1, yVar2, height, width, margin, colour } = props;

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const x = d => d[xVar];
  const top = d => d[yVar1];
  const bottom = d => d[yVar2];
  const xScale = scaleBand({
    range: [0, xMax],
    domain: data.map(x),
    padding: 0.2
  });
  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: [Math.min(...data.map(bottom)), Math.max(...data.map(top))]
  });
  return (
    <svg width={width} height={height} overflow="visible">
      <Group left={margin.left} top={margin.top}>
        {data.map(d => {
          const xPoint = x(d);
          const barWidth = xScale.bandwidth();

          const barYTop = yScale(top(d));
          const barYBottom = yScale(bottom(d));
          const barHeight = barYBottom - barYTop;

          const barX = xScale(xPoint);
          const barY = yScale(top(d));
          return (
            <Bar
              key={d[xVar]}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={colour}
            />
          );
        })}
        <AxisBottom
          top={yScale(0)}
          scale={xScale}
          hideTicks
          tickFormat={t => `Q${t.slice(-1)}'${t.slice(2, 4)}`}
          tickLabelProps={() => ({ fontSize: 7, textAnchor: "middle", dy: -5 })}
        />
      </Group>
    </svg>
  );
};

Waterfall.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  xVar: PropTypes.string,
  yVar1: PropTypes.string,
  yVar2: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  margin: PropTypes.shape(),
  colour: PropTypes.string
};

Waterfall.defaultProps = {
  data: [],
  xVar: "",
  yVar1: "",
  yVar2: "",
  height: 300,
  width: 500,
  margin: { top: 20, bottom: 20, left: 20, right: 20 },
  colour: ""
};

export default Waterfall;
