import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { Box, Typography } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { getCompareDates } from "../../utils";

const styles = {
  box: { mx: 4, py: 2, bgcolor: "#f7f3f2", borderRadius: 1 },
  title: { display: "flex", alignItems: "center", justifyContent: "center" }
};
const showCompareDate = (periodOption, startDate, endDate) => {
  const [compareStartDate, compareEndDate] = getCompareDates(
    periodOption,
    startDate,
    endDate
  );
  return `${format(compareStartDate, "dd/MM/yyyy")} - ${format(
    compareEndDate,
    "dd/MM/yyyy"
  )}`;
};

const CompareDateBox = props => {
  const { periodOption, startDate, endDate } = props;
  return (
    <Box sx={styles.box}>
      <Typography variant="body2" align="center" gutterBottom sx={styles.title}>
        <DateRange />
        &nbsp;Compare Date:
      </Typography>
      <Typography variant="subtitle2" align="center">
        {periodOption !== "Custom" && startDate && endDate
          ? showCompareDate(periodOption, startDate, endDate)
          : "-"}
      </Typography>
    </Box>
  );
};

CompareDateBox.propTypes = {
  periodOption: PropTypes.string,
  startDate: PropTypes.shape(),
  endDate: PropTypes.shape()
};

CompareDateBox.defaultProps = {
  periodOption: "",
  startDate: {},
  endDate: {}
};

export default CompareDateBox;
