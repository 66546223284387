import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Card, CardContent, Typography } from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { BarChart } from "./Charts";

const getData = data => {
  const mappedData = [];
  _.forEach(data, val => {
    mappedData.push({ name: val.name, value: val["% change"] });
  });
  return mappedData;
};

const Parent = props => {
  const { data, nl, theme } = props;
  const chartData = getData(data);
  return (
    <Card raised sx={{ height: 1, width: 1, bgcolor: theme.secondary }}>
      <CardContent sx={{ height: 110, bgcolor: "white" }}>
        <ParentSize>
          {({ width, height }) => (
            <BarChart
              data={chartData}
              xVar="name"
              yVar="value"
              height={height}
              width={width}
              margin={{ top: 15, bottom: 30, left: 0, right: 0 }}
              colours={[theme.primary, theme.darkgrey, theme.lightgrey]}
            />
          )}
        </ParentSize>
      </CardContent>
      <CardContent>
        <Typography variant="dashboardBody">{nl[0]}</Typography>
      </CardContent>
    </Card>
  );
};

Parent.propTypes = {
  data: PropTypes.shape(),
  nl: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.shape()
};

Parent.defaultProps = {
  data: {},
  nl: [],
  theme: {}
};

export default Parent;
