import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Group } from "@visx/group";
import { BarStackHorizontal } from "@visx/shape";
import { scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import { AxisLeft } from "@visx/axis";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { Grid, Typography } from "@mui/material";

const StackedBarChart = props => {
  const { data, yVar, height, width, margin, colour, moreInfo } = props;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true
  });
  let tooltipTimeout;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const y = d => d[yVar];
  const keys = _.keys(data[0]).filter(d => d !== yVar && d !== moreInfo);
  const xScale = scaleLinear({
    domain: [0, 100],
    round: true,
    range: [0, xMax]
  });
  const yScale = scaleBand({
    range: [0, yMax],
    domain: data.map(y),
    padding: 0.2
  });
  const colorScale = scaleOrdinal({
    domain: keys,
    range: colour
  });
  return (
    <Fragment>
      <svg width={width} height={height} overflow="visible">
        <Group top={margin.top} left={margin.left}>
          <BarStackHorizontal
            data={data.map(i => _.omit(i, moreInfo))}
            keys={keys}
            height={yMax}
            y={y}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {barStacks =>
              barStacks.map(barStack =>
                barStack.bars.map(bar => (
                  <g
                    key={`${bar.bar.data[yVar]}-${bar.key}`}
                    ref={containerRef}
                  >
                    <rect
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={() => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: bar.y,
                          tooltipLeft: bar.x
                        });
                      }}
                    />
                    <text
                      x={bar.x + bar.width / 2}
                      y={bar.y + 2.5 + bar.height / 2}
                      fontSize={8}
                      textAnchor="middle"
                    >
                      {bar.bar.data[bar.key] > 20 &&
                        `${bar.bar.data[bar.key]}%`}
                    </text>
                  </g>
                ))
              )
            }
          </BarStackHorizontal>
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            tickLabelProps={() => ({ fontSize: 7, dy: 8, dx: 4 })}
            ticksComponent={ticks =>
              ticks.ticks.map((t, i) => (
                <foreignObject
                  key={t.value}
                  x={-margin.left}
                  y={t.to.y - ticks.tickLabelProps[i].dy}
                  width={margin.left - ticks.tickLabelProps[i].dx}
                  height={100}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end"
                    }}
                  >
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: ticks.tickLabelProps[i].fontSize,
                        lineHeight: 0.9,
                        margin: 0
                      }}
                    >
                      {t.value}
                    </p>
                    {moreInfo.length > 0 && (
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: ticks.tickLabelProps[i].fontSize,
                          lineHeight: 1,
                          margin: 0
                        }}
                      >
                        ({data[i][moreInfo]} pts vs YA)
                      </p>
                    )}
                  </div>
                </foreignObject>
              ))
            }
          />
        </Group>
      </svg>
      <div style={{ fontSize: 7, marginTop: -20 }}>
        <LegendOrdinal scale={colorScale}>
          {labels => (
            <Grid container justifyContent="center">
              {labels.map(label => (
                <LegendItem key={label.text} margin="1px 4px">
                  <svg width={10} height={10}>
                    <circle fill={label.value} r={5} cx={5} cy={5} />
                  </svg>
                  <LegendLabel margin="0 0 0 4px">{label.text}</LegendLabel>
                </LegendItem>
              ))}
            </Grid>
          )}
        </LegendOrdinal>
      </div>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
          <Typography variant="subtitle3">
            {tooltipData.key} ({tooltipData.bar.data.type})
          </Typography>
          <Typography variant="caption">
            {tooltipData.bar.data[tooltipData.key]}%
          </Typography>
        </TooltipInPortal>
      )}
    </Fragment>
  );
};

StackedBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  yVar: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  margin: PropTypes.shape(),
  colour: PropTypes.arrayOf(PropTypes.string),
  moreInfo: PropTypes.string
};

StackedBarChart.defaultProps = {
  data: [],
  yVar: "",
  height: 300,
  width: 500,
  margin: { top: 20, bottom: 20, left: 20, right: 20 },
  colour: [],
  moreInfo: ""
};

export default StackedBarChart;
