import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  FormControl,
  TextField,
  Button,
  FormHelperText,
  ListItem,
  Box
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Loader } from "../SharedComponents";
import { changePassword } from "../../actions/user";

const ChangePassword = props => {
  const {
    userId,
    error,
    message,
    submitChangePasswordForm,
    isLoading,
    onClose
  } = props;
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const reset = () => {
    setPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  return (
    <Card sx={{ m: 2, p: 2 }}>
      <CardHeader
        titleTypographyProps={{
          variant: "h4"
        }}
        avatar={
          <IconButton
            color="secondary"
            onClick={() => {
              onClose();
              reset();
            }}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        title="Change Password"
      />
      <Grid item md={6} sx={{ m: 2 }}>
        <form>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Current password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="New password"
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Confirm new password"
                type="password"
                value={confirmNewPassword}
                onChange={e => setConfirmNewPassword(e.target.value)}
                variant="outlined"
              />
            </FormControl>
          </ListItem>
          <ListItem sx={{ my: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ my: 2 }}
            >
              <Grid item md={6}>
                <Box textAlign="left">
                  <Button
                    variant="contained"
                    color="primary"
                    type="sumbit"
                    disableElevation
                    onClick={e => {
                      e.preventDefault();
                      submitChangePasswordForm(
                        userId,
                        password,
                        newPassword,
                        confirmNewPassword
                      );
                      // if success
                      if (message.length > 0) {
                        reset();
                      }
                    }}
                  >
                    Change password
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box textAlign="right">
                  <Button
                    color="primary"
                    type="cancel"
                    disableElevation
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                      reset();
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
          {error.length > 0 ? (
            <FormHelperText error sx={{ textAlign: "center" }}>
              {error.startsWith("ERROR:") ? error.substring(12) : error}
            </FormHelperText>
          ) : null}
          {message.length > 0 ? (
            <FormHelperText sx={{ textAlign: "center" }}>
              {message}
            </FormHelperText>
          ) : null}
        </form>
        {isLoading ? <Loader /> : null}
      </Grid>
    </Card>
  );
};

ChangePassword.propTypes = {
  submitChangePasswordForm: PropTypes.func,
  error: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.string
};

ChangePassword.defaultProps = {
  submitChangePasswordForm: () => {},
  error: "",
  message: "",
  isLoading: false,
  onClose: () => {},
  userId: ""
};

const mapDispatchToProps = dispatch => ({
  submitChangePasswordForm: (
    userId,
    password,
    newPassword,
    confirmNewPassword
  ) => {
    dispatch(changePassword(userId, password, newPassword, confirmNewPassword));
  }
});

export default connect(null, mapDispatchToProps)(ChangePassword);
