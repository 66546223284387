import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { Grid, Typography } from "@mui/material";

const PieChart = props => {
  const { data, xVar, yVar, legend, height, width, margin } = props;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true
  });
  let tooltipTimeout;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const scale = scaleOrdinal({
    domain: data.map(d => d[xVar]),
    range: data.map(d => d.color)
  });
  return (
    <Fragment>
      <svg width={width} height={height}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie data={data} pieValue={d => d[yVar]} outerRadius={radius}>
            {pie =>
              pie.arcs.map(arc => {
                const [centroidX, centroidY] = pie.path.centroid(arc);
                const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.5;
                const arcPath = pie.path(arc);
                const arcFill = arc.data.color;
                return (
                  <g key={arc.data[xVar]} ref={containerRef}>
                    <path
                      d={arcPath}
                      fill={arcFill}
                      stroke="#fff"
                      strokeWidth={0.25}
                      strokeOpacity={0.5}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={() => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        showTooltip({
                          tooltipData: arc.data,
                          tooltipTop: centroidY,
                          tooltipLeft: centroidX
                        });
                      }}
                    />
                    {hasSpaceForLabel ? (
                      <text
                        x={centroidX}
                        y={centroidY}
                        fontSize={8}
                        textAnchor="middle"
                      >
                        {arc.data[yVar].toFixed(1)}%
                      </text>
                    ) : (
                      (pie.arcs.length === 2 ||
                        arc.data[yVar].toFixed(1) > 0.0) && (
                        <g>
                          <line
                            x1={2.1 * centroidX}
                            x2={2.4 * centroidX}
                            y1={2.1 * centroidY}
                            y2={2.4 * centroidY}
                            stroke="#000"
                            strokeWidth={0.25}
                            strokeOpacity={0.5}
                          />
                          <text
                            x={2.5 * centroidX}
                            y={2.5 * centroidY}
                            fontSize={8}
                            textAnchor="middle"
                          >
                            {arc.data[yVar].toFixed(1)}%
                          </text>
                        </g>
                      )
                    )}
                  </g>
                );
              })
            }
          </Pie>
        </Group>
      </svg>
      {legend && (
        <div style={{ fontSize: 7, marginTop: -20 }}>
          <LegendOrdinal scale={scale}>
            {labels => (
              <Grid container justifyContent="center">
                {labels.map(label => (
                  <LegendItem key={label.text} margin="1px 4px">
                    <svg width={10} height={10}>
                      <circle fill={label.value} r={5} cx={5} cy={5} />
                    </svg>
                    <LegendLabel margin="0 0 0 4px">{label.text}</LegendLabel>
                  </LegendItem>
                ))}
              </Grid>
            )}
          </LegendOrdinal>
        </div>
      )}
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
          <Typography variant="subtitle3">{tooltipData.name}</Typography>
          <Typography variant="caption">
            {tooltipData.value.toFixed(1)}%
          </Typography>
        </TooltipInPortal>
      )}
    </Fragment>
  );
};

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  xVar: PropTypes.string,
  yVar: PropTypes.string,
  legend: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  margin: PropTypes.shape()
};

PieChart.defaultProps = {
  data: [],
  xVar: "",
  yVar: "",
  legend: false,
  height: 300,
  width: 500,
  margin: { top: 20, bottom: 20, left: 20, right: 20 }
};

export default PieChart;
