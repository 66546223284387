import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { PieChart, Heatmap } from "./Charts";
import { up, down } from "../../images";

const PerformanceContext = props => {
  const { data, nl, theme, target, context } = props;
  const getPieData = inputData => {
    const mappedData = {};
    _.forEach(inputData, (val, key) => {
      mappedData[key] = [
        { name: [target], value: val.t2, color: theme[key] },
        { name: "Other", value: 100 - val.t2, color: theme.lightgrey }
      ];
    });
    return mappedData;
  };
  const pieData = getPieData(data.overview_context);
  return (
    <Card raised sx={{ height: 1, width: 1 }}>
      <CardContent>
        <Grid container justifyContent="space-evenly" spacing={2}>
          <Grid item md={12} container>
            <Grid item md={4}>
              <Typography
                variant="subtitle2"
                color={theme.secondary}
                align="center"
                sx={{ lineHeight: 1.3 }}
              >
                Value Share
              </Typography>
              <Typography
                variant="dashboardBody"
                color={theme.secondary}
                align="center"
              >
                ({data.overview_context["Value Sales"].diff} pts vs YA)
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography
                variant="subtitle2"
                color={theme.secondary}
                align="center"
                sx={{ lineHeight: 1.3 }}
              >
                Volume Share
              </Typography>
              <Typography
                variant="dashboardBody"
                color={theme.secondary}
                align="center"
              >
                ({data.overview_context["Volume Sales"].diff} pts vs YA)
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography
                variant="subtitle2"
                color={theme.secondary}
                align="center"
                sx={{ lineHeight: 1.3 }}
              >
                Basket Incidence in {context}
              </Typography>
              <Typography
                variant="dashboardBody"
                color={theme.secondary}
                align="center"
              >
                ({data.overview_context["Basket Incidence"].diff} pts vs YA)
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} container sx={{ mt: -2 }}>
            <Grid item md={4}>
              <Box height={150}>
                <ParentSize>
                  {({ width, height }) => (
                    <PieChart
                      data={pieData["Value Sales"]}
                      xVar="name"
                      yVar="value"
                      width={width}
                      height={height}
                    />
                  )}
                </ParentSize>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box height={150}>
                <ParentSize>
                  {({ width, height }) => (
                    <PieChart
                      data={pieData["Volume Sales"]}
                      xVar="name"
                      yVar="value"
                      width={width}
                      height={height}
                    />
                  )}
                </ParentSize>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box height={150}>
                <ParentSize>
                  {({ width, height }) => (
                    <PieChart
                      data={pieData["Basket Incidence"]}
                      xVar="name"
                      yVar="value"
                      width={width}
                      height={height}
                    />
                  )}
                </ParentSize>
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Card
              variant="outlined"
              sx={{ p: 1, mt: -2, width: 1, bgcolor: theme.secondary }}
            >
              <Typography variant="dashboardBody">
                {nl.overview_context.join(" ")}
              </Typography>
            </Card>
          </Grid>
          <Grid item md={12} container justifyContent="space-between">
            <Grid item md={7}>
              <Box height={150}>
                <ParentSize>
                  {({ width, height }) => (
                    <Heatmap
                      data={data.heatmap}
                      xVar="day"
                      yVar="times"
                      zVar="time"
                      val="value"
                      height={height}
                      width={width}
                      margin={{
                        top: 20,
                        bottom: 20,
                        left: 60,
                        right: 20
                      }}
                      colours={[theme.light, theme.primary]}
                    />
                  )}
                </ParentSize>
              </Box>
            </Grid>
            <Grid item md={5}>
              <Card
                variant="outlined"
                sx={{ p: 1, width: 1, bgcolor: theme.secondary }}
              >
                {nl.heatmap.map(i => (
                  <Typography key={i} variant="dashboardBody">
                    {i}
                  </Typography>
                ))}
              </Card>
            </Grid>
          </Grid>
          <Grid item md={12} container alignItems="center">
            <Grid item md={1}>
              <img alt="up" src={up} style={{ width: 30, height: 30 }} />
            </Grid>
            <Grid item md={11}>
              <Card
                variant="outlined"
                sx={{ p: 1, width: 1, bgcolor: theme.secondary }}
              >
                <Typography variant="dashboardBody">
                  {nl.basket_context.positive}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid item md={12} container alignItems="center">
            <Grid item md={1}>
              <img alt="down" src={down} style={{ width: 30, height: 30 }} />
            </Grid>
            <Grid item md={11}>
              <Card
                variant="outlined"
                sx={{ p: 1, width: 1, bgcolor: theme.secondary }}
              >
                <Typography variant="dashboardBody">
                  {nl.basket_context.negative}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PerformanceContext.propTypes = {
  data: PropTypes.shape(),
  nl: PropTypes.shape(),
  theme: PropTypes.shape(),
  target: PropTypes.string,
  context: PropTypes.string
};

PerformanceContext.defaultProps = {
  data: {},
  nl: {},
  theme: {},
  target: "",
  context: ""
};

export default PerformanceContext;
