import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AppBar, Toolbar, Button, Popover, Box } from "@mui/material";
import { AccountCircleOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { logout } from "../actions/user";
import { SettingsIcon, LogoutIcon } from "./Icons";
import { clearStaticDataCache } from "../actions/data";
import { clearAllSearchData } from "../actions/search";
import { logo } from "../images";

const styles = {
  container: { width: "calc(90% - 80px)" },
  menuItem: { mx: 1, fontWeight: "normal", textTransform: "none" },
  subMenuItem: { mx: 0, px: 3, display: "flex", justifyContent: "left" },
  icon: { height: 16, width: 16 }
};

const MenuBar = props => {
  const { onLogout } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="secondaryDark" position="static" sx={{ height: 100 }}>
        <Toolbar>
          <Link to="/">
            <img
              src={logo}
              height="100%"
              alt="Interrodata"
              style={{ height: 140 }}
            />
          </Link>
          <Button
            sx={styles.menuItem}
            variant="contained"
            color="secondaryDark"
            disableElevation
            component={Link}
            to="/"
          >
            Home
          </Button>
          <Button
            sx={styles.menuItem}
            variant="contained"
            color="secondaryDark"
            disableElevation
            component={Link}
            to="/reports-reviews/product-performance"
          >
            Reports & Reviews
          </Button>
          <Button
            sx={styles.menuItem}
            variant="contained"
            color="secondaryDark"
            disableElevation
            component={Link}
            to="/support"
          >
            Support
          </Button>
          <Box component="div" sx={{ flexGrow: 1 }} />
          <Button
            sx={styles.menuItem}
            variant="contained"
            color="secondaryDark"
            disableElevation
            onClick={openMenu}
          >
            <AccountCircleOutlined sx={{ height: 28, width: 28, mx: 1 }} />
          </Button>
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Button
              sx={{ ...styles.menuItem, ...styles.subMenuItem }}
              color="secondaryDark"
              component={Link}
              to="/settings"
              onClick={closeMenu}
              startIcon={<SettingsIcon sx={styles.icon} />}
              fullWidth
            >
              Settings
            </Button>
            <Button
              sx={{ ...styles.menuItem, ...styles.subMenuItem }}
              color="secondaryDark"
              component={Link}
              to="/logout"
              onClick={() => {
                onLogout();
                closeMenu();
              }}
              startIcon={<LogoutIcon sx={styles.icon} />}
              fullWidth
            >
              Logout
            </Button>
          </Popover>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

MenuBar.propTypes = {
  onLogout: PropTypes.func
};

MenuBar.defaultProps = {
  onLogout: () => {}
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(logout());
    // force data refresh on logout
    dispatch(clearStaticDataCache());
    dispatch(clearAllSearchData());
  }
});

export default connect(null, mapDispatchToProps)(MenuBar);
