import defaultState from "./defaultState";
import {
  LOAD_REPORT_REQUEST,
  LOAD_REPORT_SUCCESS,
  LOAD_REPORT_ERROR,
  GET_MY_RECENT_REPORTS_REQUEST,
  GET_MY_RECENT_REPORTS_SUCCESS,
  GET_MY_RECENT_REPORTS_ERROR,
  GET_MY_FAVOURITE_REPORTS_REQUEST,
  GET_MY_FAVOURITE_REPORTS_SUCCESS,
  GET_MY_FAVOURITE_REPORTS_ERROR,
  // ADD_TO_FAVOURITES_REQUEST,
  ADD_TO_FAVOURITES_SUCCESS,
  // ADD_TO_FAVOURITES_ERROR,
  // REMOVE_FROM_FAVOURITES_REQUEST,
  REMOVE_FROM_FAVOURITES_SUCCESS
  // REMOVE_FROM_FAVOURITES_ERROR
} from "../constants/actionConstants";

const report = (state = defaultState.report, action) => {
  switch (action.type) {
    case LOAD_REPORT_REQUEST:
      return {
        ...state,
        reportQuery: action.report,
        isLoading: true
      };
    case LOAD_REPORT_SUCCESS:
      return {
        ...state,
        error: {},
        reportContent: action.report.data,
        reportTitle: action.report.title,
        reportClient: action.report.client,
        reportId: action.report.id,
        reportStory: action.report.story,
        reportMeta: action.report.meta,
        isLoading: false
      };
    case LOAD_REPORT_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case GET_MY_RECENT_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingMyReports: true,
        myRecentReportsError: ""
      };
    case GET_MY_RECENT_REPORTS_SUCCESS:
      return {
        ...state,
        myRecentReports: action.myRecentReports,
        isLoadingMyReports: false,
        myRecentReportsError: ""
      };
    case GET_MY_RECENT_REPORTS_ERROR:
      return {
        ...state,
        isLoadingMyReports: false,
        myRecentReportsError: action.error
      };
    case GET_MY_FAVOURITE_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingMyFavourites: true,
        myFavouriteReportsError: ""
      };
    case GET_MY_FAVOURITE_REPORTS_SUCCESS:
      return {
        ...state,
        myFavouriteReports: action.myFavouriteReports,
        isLoadingMyFavourites: false,
        myFavouriteReportsError: ""
      };
    case GET_MY_FAVOURITE_REPORTS_ERROR:
      return {
        ...state,
        isLoadingMyFavourites: false,
        myFavouriteReportsError: action.error
      };
    case ADD_TO_FAVOURITES_SUCCESS:
      return {
        ...state,
        myFavouriteReports: [...state.myFavouriteReports, action.report]
      };
    case REMOVE_FROM_FAVOURITES_SUCCESS:
      return {
        ...state,
        myFavouriteReports: [
          ...state.myFavouriteReports.filter(i => i.query !== action.query)
        ]
      };
    default:
      return state;
  }
};

export default report;
