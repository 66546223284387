import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import _ from "lodash";
import {
  ListItem,
  Radio,
  FormControlLabel,
  Typography,
  FormHelperText,
  FormControl,
  FormLabel,
  RadioGroup
} from "@mui/material";
import { TD_PRODUCTS } from "../../constants";
import { enterSelectedTerm, deleteTerm } from "../../actions";
import { customFilter, customStyles } from "../../utils";

const Product = props => {
  const {
    subsections,
    isStaticData,
    productError,
    onSelect,
    story,
    product,
    clearErrors,
    context
  } = props;
  const [productType, setProductType] = useState(null);
  useEffect(() => {
    setProductType(product ? product.subsection : null);
  }, []);

  const getOptionsFromList = (optionList, subsection) =>
    Object.assign(
      optionList.map(i => ({ value: `${subsection}--${i}`, label: i }))
    );
  const getSelectedValue = selected =>
    selected
      ? {
          value: `${selected.subsection}--${selected.name}`,
          label: selected.name
        }
      : "no-value";
  const getList = (subsectionsToUse, subsection) => {
    const list = subsectionsToUse.filter(i => i.name === subsection);
    return isStaticData && list.length > 0 ? list[0].keywords.sort() : [];
  };
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            Select Distributor or Brand
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
          Which Distributor or Brand do you want to run a report for?
        </Typography>
        <RadioGroup>
          {TD_PRODUCTS.map(i => (
            <ListItem key={i} sx={{ px: 1 }}>
              <FormControlLabel
                sx={{ width: 150 }}
                control={
                  <Radio
                    value={i}
                    checked={productType === i}
                    onChange={e => {
                      setProductType(e.target.value);
                      if (product) {
                        onSelect("no-value", getSelectedValue(product), story);
                      }
                    }}
                  />
                }
                label={
                  <Typography variant="body2">{_.startCase(i)}</Typography>
                }
              />
              <Select
                styles={customStyles}
                className="smallSelect"
                onChange={(e, j) => {
                  setProductType(i);
                  if (j.action === "clear") {
                    // remove from search terms
                    onSelect(
                      "no-value",
                      getSelectedValue(product),
                      story,
                      context
                    );
                  } else {
                    onSelect(
                      e.value,
                      getSelectedValue(product),
                      story,
                      context
                    );
                  }
                  clearErrors();
                }}
                options={getOptionsFromList(getList(subsections, i), i)}
                placeholder={`Type or scroll to select a ${_.lowerCase(i)}`}
                value={productType === i && getSelectedValue(product)}
                backspaceRemovesValue={false}
                filterOption={customFilter}
              />
            </ListItem>
          ))}
        </RadioGroup>
        <FormHelperText error>{productError}</FormHelperText>
      </FormControl>
    </ListItem>
  );
};

Product.propTypes = {
  story: PropTypes.string,
  onSelect: PropTypes.func,
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  isStaticData: PropTypes.bool,
  product: PropTypes.objectOf(PropTypes.string),
  productError: PropTypes.string,
  clearErrors: PropTypes.func,
  context: PropTypes.objectOf(PropTypes.string)
};

Product.defaultProps = {
  story: "",
  onSelect: () => {},
  subsections: [],
  isStaticData: false,
  product: undefined,
  productError: "",
  clearErrors: () => {},
  context: undefined
};

const mapDispatchToProps = dispatch => ({
  onSelect: (selectedValue, oldValue, story, context) => {
    const value = selectedValue.split("--");
    if (oldValue !== "no-value") {
      const old = oldValue.value.split("--");
      dispatch(
        deleteTerm(
          {
            name: old[1],
            subsection: old[0],
            table: "what",
            story
          },
          story
        )
      );
    }
    if (selectedValue !== "no-value") {
      dispatch(
        enterSelectedTerm(
          {
            name: value[1],
            subsection: value[0],
            table: "what",
            story
          },
          story
        )
      );
    }
    if (context) {
      dispatch(deleteTerm(context, story));
    }
  }
});

export default connect(null, mapDispatchToProps)(Product);
