import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { FormControl, FormLabel, Typography, Grid } from "@mui/material";
import _ from "lodash";
import {
  enterSelectedTerm,
  deleteTerm,
  filterStaticData,
  removeFilterTerm,
  clearSearchHints,
  getContext
} from "../../actions/search";
import { customFilter, customStyles } from "../../utils";
import { GROUPINGS } from "../../constants";

const AdvancedSearch = props => {
  const {
    options,
    searchTerms,
    onSelect,
    filterTerms,
    story,
    isFilteringData,
    clearErrors,
    hint,
    isContext,
    whatTerms,
    client,
    disableMultiple,
    disableFiltering
  } = props;
  return (
    <Fragment>
      {GROUPINGS.map(group => (
        <FormControl
          component="fieldset"
          className="fieldset"
          key={group}
          sx={{ p: 2, pb: 3, mb: 2 }}
          fullWidth
        >
          <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
            <Typography color="textPrimary" fontWeight="bold">
              {_.upperFirst(group)}
            </Typography>
          </FormLabel>
          <Grid container spacing={3} sx={{ px: 1 }}>
            {options
              .filter(({ grouping }) => grouping === group)
              .map(({ name }) => (
                <Grid item md={6} key={name}>
                  <Typography variant="body2">{_.startCase(name)}</Typography>
                  <Select
                    styles={customStyles}
                    isDisabled={
                      isFilteringData ||
                      (disableMultiple &&
                        searchTerms.length > 0 &&
                        searchTerms.filter(i => i.subsection !== name).length >
                          0)
                    }
                    onChange={(val, e) => {
                      const terms = searchTerms
                        .filter(i => i.subsection === name)
                        .map(j => ({
                          value: `${j.subsection}--${j.name}`,
                          label: `${j.name}`
                        }));
                      onSelect(
                        e,
                        terms,
                        filterTerms,
                        story,
                        hint,
                        isContext,
                        whatTerms,
                        client,
                        disableFiltering
                      );
                      clearErrors();
                    }}
                    options={
                      disableMultiple &&
                      searchTerms.length > 0 &&
                      searchTerms.filter(i => i.subsection === name).length > 0
                        ? []
                        : options
                            .filter(o => o.name === name)
                            .map(option =>
                              option.keywords.map(kw => ({
                                name: kw,
                                subsection: option.name
                              }))
                            )
                            .reduce((array, option) => array.concat(option), [])
                            .sort((a, b) => {
                              const numRegex = /^-{0,1}\d*\.{0,1}\d+$/;
                              if (
                                numRegex.test(a.name) &&
                                numRegex.test(b.name)
                              ) {
                                return a.name - b.name;
                              }
                              if (a.name < b.name) return -1;
                              if (a.name > b.name) return 1;
                              return 0;
                            })
                            .map(option => ({
                              value: `${option.subsection}--${
                                option.name.value
                                  ? option.name.value
                                  : option.name
                              }`,
                              label: `${
                                option.name.value
                                  ? option.name.value
                                  : option.name
                              }`
                            }))
                    }
                    placeholder={`Type or scroll to select a ${name
                      .split("_")
                      .join(" ")}`}
                    value={searchTerms
                      .filter(i => i.subsection === name)
                      .map(e => ({
                        value: `${e.subsection}--${
                          e.name.value ? e.name.value : e.name
                        }`,
                        label: `${e.name.value ? e.name.value : e.name}`
                      }))}
                    backspaceRemovesValue={false}
                    isMulti
                    filterOption={customFilter}
                  />
                </Grid>
              ))}
          </Grid>
        </FormControl>
      ))}
    </Fragment>
  );
};

AdvancedSearch.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  options: PropTypes.arrayOf(PropTypes.shape()),
  filterTerms: PropTypes.arrayOf(PropTypes.shape()),
  onSelect: PropTypes.func,
  hint: PropTypes.string,
  story: PropTypes.string,
  isFilteringData: PropTypes.bool,
  clearErrors: PropTypes.func,
  isContext: PropTypes.bool,
  whatTerms: PropTypes.arrayOf(PropTypes.shape()),
  client: PropTypes.string,
  disableMultiple: PropTypes.bool,
  disableFiltering: PropTypes.bool
};

AdvancedSearch.defaultProps = {
  searchTerms: [],
  options: [],
  filterTerms: [],
  onSelect: () => {},
  hint: "",
  story: "",
  isFilteringData: false,
  clearErrors: () => {},
  isContext: false,
  whatTerms: [],
  client: "",
  disableMultiple: false,
  disableFiltering: false
};

const mapDispatchToProps = dispatch => ({
  onSelect: (
    e,
    terms,
    filterTerms,
    story,
    hint,
    isContext,
    whatTerms,
    client,
    disableFiltering
  ) => {
    if (_.isEqual(e.action, "select-option")) {
      const value = e.option.value.split("--");
      const term = {
        name: value[1],
        subsection: value[0],
        table: isContext ? "context" : "what",
        story
      };
      dispatch(enterSelectedTerm(term, story));
      if (hint && hint.includes("product")) {
        dispatch(clearSearchHints());
      }
      if (isContext) {
        const context = [...filterTerms, term];
        dispatch(getContext(whatTerms, context, "product", story, client));
      } else if (!disableFiltering) {
        dispatch(filterStaticData(term, filterTerms, story, client));
      }
    } else if (_.isEqual(e.action, "remove-value")) {
      const value = e.removedValue.value.split("--");
      const term = {
        name: value[1],
        subsection: value[0],
        table: isContext ? "context" : "what",
        story
      };
      dispatch(deleteTerm(term, story));
      if (isContext) {
        const context = filterTerms.filter(t => !_.isEqual(t, term));
        dispatch(getContext(whatTerms, context, "product", story, client));
      } else if (!disableFiltering) {
        dispatch(removeFilterTerm(term, filterTerms, story, client));
      }
    } else if (_.isEqual(e.action, "clear")) {
      terms.forEach(i => {
        const value = i.value.split("--");
        const term = {
          name: value[1],
          subsection: value[0],
          table: isContext ? "context" : "what",
          story
        };
        dispatch(deleteTerm(term, story));
        // TODO: remove all filter terms at once for efficiency
        if (isContext) {
          const context = filterTerms.filter(t => !_.isEqual(t, term));
          dispatch(getContext(whatTerms, context, "product", story, client));
        } else if (!disableFiltering) {
          dispatch(removeFilterTerm(term, filterTerms, story, client));
        }
      });
    }
  }
});

export default connect(null, mapDispatchToProps)(AdvancedSearch);
