import {
  parse,
  format,
  startOfWeek,
  startOfWeekYear,
  endOfWeek,
  subWeeks,
  addWeeks,
  getWeek,
  getYear,
  setDay,
  differenceInWeeks,
  compareDesc,
  parseISO,
  isValid
} from "date-fns";
import _ from "lodash";
import { enGB } from "date-fns/locale";
import config from "../config";

const { isRestatement, restatementYears } = config;

/**
 * Date options: allow 53 weeks, week starts Sunday
 */
const options = {
  weekStartsOn: 0,
  firstWeekContainsDate: 4,
  useAdditionalWeekYearTokens: true
};

/**
 * Custom locale for datepicker, allows for 53 weeks, week starts Sunday
 */
export const locale = { ...enGB, options };

/**
 * Parse allowing for multiple date formats
 * Based on https://gist.github.com/krutoo/c88dc9259e0ff531f3a640d5c3c6f267
 */
const parseMultiple = (
  dateString,
  formatStrings,
  referenceDate,
  parseOptions
) => {
  let result;
  for (let i = 0; i < formatStrings.length; i += 1) {
    result = parse(dateString, formatStrings[i], referenceDate, parseOptions);
    if (isValid(result)) {
      break;
    }
  }
  return result;
};

/**
 * Format the datepicker week number
 * @param  {Object} date    Date
 */
export const formatWeekNumber = date => {
  const year = getYear(date);
  if (isRestatement) {
    return getWeek(date, options);
  }
  // after restatement
  if (
    _.includes(restatementYears, year) ||
    _.includes(restatementYears, year + 1) ||
    _.includes(restatementYears, year + 2)
  ) {
    const weekNumber = getWeek(date, options) - 1;
    return weekNumber === 0 ? 52 : weekNumber;
  }
  const weekNumber = getWeek(date, options);
  // after restatement - no 53 week year
  return weekNumber === 53 ? 52 : weekNumber;
};

/**
 * Get the date of the start i.e. Sunday of a given week
 * @param  {Object} date    Date in format ddMMyy or YYYYww - where ww is the week number (1-53)
 */
export const getWeekStart = date =>
  startOfWeek(
    parseMultiple(date, ["ddMMyy", "YYYYww"], new Date(), options),
    options
  );

/**
 * Get the date of the end i.e. Saturday of a given week
 * @param  {Object} date    Date in format ddMMyy or YYYYww - where ww is the week number (1-53)
 */
export const getWeekEnd = date =>
  endOfWeek(
    parseMultiple(date, ["ddMMyy", "YYYYww"], new Date(), options),
    options
  );

/**
 * Get the comparison period dates from the initial period dates
 * @param  {Object} periodOption    Option for comparison period - yearAgo, justBefore or Custom
 * @param  {Object} start           Date for the start of the initial period
 * @param  {Object} end             Date for the end of the initial period
 * @param  {Object} customStart     Date for the start of the custom compare period - optional
 * @param  {Object} customEnd       Date for the end of the custom compare period - optional
 * @return {Array}                  Array containing the comparison start date and comparison end date
 */
export const getCompareDates = (
  periodOption,
  start,
  end,
  customStart,
  customEnd
) => {
  let compareStart;
  let compareEnd;
  if (periodOption === "yearAgo") {
    compareStart = subWeeks(start, 52);
    compareEnd = subWeeks(end, 52);
  } else if (periodOption === "justBefore") {
    const diff = differenceInWeeks(end, start) + 1;
    compareStart = subWeeks(start, diff);
    compareEnd = subWeeks(end, diff);
  } else if (periodOption === "Custom") {
    compareStart = customStart;
    compareEnd = customEnd;
  }
  return [compareStart, compareEnd];
};

/**
 * Convert custom dates to correct format for the backend
 * @param  {Object}   start           Date for the start of the initial period
 * @param  {Object}   end             Date for the end of the initial period
 * @param  {Object}   compareStart    Date for the start of the compare period
 * @param  {Object}   compareEnd      Date for the end of the compare period
 * @return {String}                   Comma separated string of 4 dates chronologically in format ddMMyy or MMMYY
 */
export const getDatePeriod = (start, end, compareStart, compareEnd) =>
  `${format(endOfWeek(compareStart, options), "ddMMyy", options)},${format(
    endOfWeek(compareEnd, options),
    "ddMMyy",
    options
  )},${format(endOfWeek(start, options), "ddMMyy", options)},${format(
    endOfWeek(end, options),
    "ddMMyy",
    options
  )}`;

/**
 * Convert date periods to the exact dates
 * @param  {String} period        Name of period used
 * @param  {Object} dataDate      The last date in the data
 * @return {Array}                Array of the start and end date of initial and compare period
 */
export const getDateFromPeriod = (period, dataDate) => {
  const lastValidDate = dataDate
    ? parse(dataDate, "dd/MM/yy", new Date())
    : new Date();
  let start;
  let end;
  let compareStart;
  let compareEnd;
  switch (period) {
    case "Year to Date":
      start = startOfWeekYear(lastValidDate, options);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 52 Weeks":
      start = setDay(subWeeks(lastValidDate, 52), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 24 Weeks":
      start = setDay(subWeeks(lastValidDate, 24), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 12 Weeks":
      start = setDay(subWeeks(lastValidDate, 12), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    case "Latest 4 Weeks":
      start = setDay(subWeeks(lastValidDate, 4), 7);
      end = lastValidDate;
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(end, 52);
      return [compareStart, compareEnd, start, end];
    default:
      // year
      start =
        (((!isRestatement &&
          (_.includes(restatementYears, _.toNumber(period)) ||
            _.includes(restatementYears, _.toNumber(period) + 1))) ||
          _.includes(restatementYears, _.toNumber(period) + 2)) &&
          addWeeks(startOfWeekYear(new Date(period, 0, 4), options), 1)) ||
        startOfWeekYear(new Date(period, 0, 4), options);
      end = setDay(
        addWeeks(
          start,
          isRestatement && _.includes(restatementYears, _.toNumber(period))
            ? 53
            : 52
        ),
        -1
      );
      compareStart = subWeeks(start, 52);
      compareEnd = subWeeks(
        end,
        isRestatement && _.includes(restatementYears, _.toNumber(period))
          ? 53
          : 52
      );
      return [compareStart, compareEnd, start, end];
  }
};

/**
 * Convert date periods to readable string for search input bar
 * @param  {String} dateString    Comma separated string of 4 dates chronologically in format YYYYWW or MMMYY
 * @return {String}               Human readable date period
 */
export const getDateLabel = dateString => {
  const dates = dateString
    .split(",")
    .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)));
  return `Custom period: (${format(dates[2], "dd/MM/yyyy")} - ${format(
    dates[3],
    "dd/MM/yyyy"
  )} Vs ${format(dates[0], "dd/MM/yyyy")} - ${format(dates[1], "dd/MM/yyyy")})`;
};

/**
 * Custom sort function to sort reports by date
 */
export const sortByDate = (a, b) =>
  compareDesc(parseISO(a.date), parseISO(b.date));

/**
 * Create a query string from a list of search terms
 * @param  {Array} searchTerms    Array of search terms, each of which is an object
 * @param  {String} story         Story type - e.g. IDA, PRR
 * @return {String}               URI encoded query string for the report URL
 */
export const buildQueryString = searchTerms =>
  searchTerms
    .filter(
      term =>
        term &&
        term.subsection &&
        term.subsection.length > 0 &&
        term.subsection !== "none"
    ) // remove filler terms (e.g. "in")
    .sort((a, b) => {
      if (a.subsection < b.subsection) return -1;
      if (a.subsection > b.subsection) return 1;
      return 0;
    }) // order so query always the same for a set group of constraints
    .map(
      (term, i) =>
        `${
          (term.table === "context" &&
            `context${i}=${encodeURIComponent(term.subsection)}`) ||
          encodeURIComponent(term.subsection)
        }=${encodeURIComponent(
          term.subsection === "period" && term.name.period
            ? term.name.period
            : term.name
        )}`
    )
    .join("&");

/**
 * Helper function for getTitleFromQueryString to produce a readable period option
 */
const getDateLabelFromPeriod = dateString => {
  const dates = dateString
    .split(",")
    .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)));
  const diff = differenceInWeeks(dates[3], dates[2]) + 1;
  return `${diff} w/e ${format(dates[3], "dd/MM/yyyy")} vs ${diff} w/e ${format(
    dates[1],
    "dd/MM/yyyy"
  )}`;
};

/**
 * Create a readable title from the query string
 * @param  {String} query   Query string for the report
 * @return {String}         Human readable title for the report
 */
export const getTitleFromQueryString = query => {
  const urlParams = new URLSearchParams(query);
  const params = Object.fromEntries(urlParams);
  const whatParams = _.omit(
    _.omitBy(params, (i, j) => j.startsWith("context")),
    ["period", "story"]
  );
  const whatString = Object.values(whatParams).join(" ");
  const contextString = _.values(
    _.pickBy(params, (i, j) => j.startsWith("context"))
  )
    .map(i => i.split("=")[1])
    .join(" ");
  return `${whatString} performance within ${contextString} in ${
    params.period.split(",").length === 4
      ? getDateLabelFromPeriod(params.period)
      : params.period
  }`;
};

/**
 * Custom search function for react-select dropdown
 * from https://github.com/JedWatson/react-select/issues/3067#issue-363771398
 * @param  {Object} option      Current option object
 * @param  {String} rawInput    User search input
 * @return {Boolean}            Should option be included based on input
 */
export const customFilter = (option, rawInput) => {
  const words = rawInput.split(" ");
  return words.reduce(
    (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
    true
  );
};

/**
 * Custom styles for react-select dropdown
 */
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? "0 0 0 1px #009dbc" : undefined,
    borderColor: state.isFocused ? "#009dbc" : provided.borderColor,
    "&:hover": {
      borderColor: "#009dbc"
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#e5e0df"
      : (state.isFocused && "#f7f3f2") || provided.backgroundColor,
    "&:hover": {
      backgroundColor: state.isSelected ? "#cac5c4" : "#f7f3f2"
    },
    color: state.isSelected ? "#171414" : provided.color
  })
};
