import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Typography
} from "@mui/material";
import { ReportsReviewsIcon, PerformanceIcon } from "../Icons";

const LeftMenu = () => (
  <Card>
    <ListItem sx={{ bgcolor: "#f9f9f9" }}>
      <ListItemIcon>
        <ReportsReviewsIcon color="secondaryDark" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="subtitle2" color="secondary.dark" fontSize={16}>
            Reports & Reviews
          </Typography>
        }
      />
    </ListItem>
    <List component="nav">
      <Link to="/reports-reviews/product-performance">
        <ListItemButton>
          <ListItemIcon>
            <PerformanceIcon color="primaryDark" fontSize="inherit" />
          </ListItemIcon>
          <ListItemText
            secondary={
              <Typography variant="body2" color="primary.dark">
                Product Performance Report
              </Typography>
            }
          />
        </ListItemButton>
      </Link>
    </List>
  </Card>
);

export default LeftMenu;
