import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, RadioGroup, Radio, Typography } from "@mui/material";

const CompareOptions = props => {
  const {
    periodOption,
    setPeriodOption,
    startDate,
    endDate,
    checkPeriodErrors,
    selectedPeriod,
    setDateError,
    setCustomStartDate,
    setCustomDateError,
    setCustomEndDate,
    isCalendarOpen,
    onSelect
  } = props;
  return (
    <RadioGroup sx={{ px: 1 }}>
      <FormControlLabel
        control={
          <Radio
            value="yearAgo"
            checked={periodOption === "yearAgo"}
            onChange={e => {
              setPeriodOption(e.target.value);
              if (startDate && endDate) {
                checkPeriodErrors(startDate, endDate);
                onSelect(startDate, endDate, e.target.value, selectedPeriod);
              }
            }}
            disabled={isCalendarOpen}
          />
        }
        label={
          <Typography variant="body2">
            Compare to the same period a year ago
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Radio
            value="justBefore"
            checked={periodOption === "justBefore"}
            onChange={e => {
              setPeriodOption(e.target.value);
              setDateError(null);
              if (startDate && endDate) {
                checkPeriodErrors(startDate, endDate);
                onSelect(startDate, endDate, e.target.value, selectedPeriod);
              }
            }}
            disabled={isCalendarOpen}
          />
        }
        label={
          <Typography variant="body2">
            Compare to the period immediately before the target period
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Radio
            value="Custom"
            checked={periodOption === "Custom"}
            onChange={e => {
              if (!startDate || !endDate) {
                setDateError("Please choose a period");
              } else {
                setPeriodOption(e.target.value);
                // reset custom dates
                setCustomStartDate(null);
                setCustomEndDate(null);
                setCustomDateError(null);
                // remove current period but don't add a new one
                onSelect(startDate, endDate, e.target.value, selectedPeriod);
              }
            }}
            disabled={isCalendarOpen}
          />
        }
        label={<Typography variant="body2">Custom date</Typography>}
      />
    </RadioGroup>
  );
};

CompareOptions.propTypes = {
  periodOption: PropTypes.string,
  setPeriodOption: PropTypes.func,
  startDate: PropTypes.shape(),
  endDate: PropTypes.shape(),
  checkPeriodErrors: PropTypes.func,
  selectedPeriod: PropTypes.objectOf(PropTypes.string),
  setDateError: PropTypes.func,
  setCustomStartDate: PropTypes.func,
  setCustomDateError: PropTypes.func,
  setCustomEndDate: PropTypes.func,
  isCalendarOpen: PropTypes.bool,
  onSelect: PropTypes.func
};

CompareOptions.defaultProps = {
  periodOption: "",
  setPeriodOption: () => {},
  startDate: {},
  endDate: {},
  checkPeriodErrors: () => {},
  selectedPeriod: undefined,
  setDateError: () => {},
  setCustomStartDate: () => {},
  setCustomDateError: () => {},
  setCustomEndDate: () => {},
  isCalendarOpen: false,
  onSelect: () => {}
};

export default CompareOptions;
