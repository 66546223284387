import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Favicon from "react-favicon";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Link, Box } from "@mui/material";
import { fetchReport, fetchLocalReport } from "../../actions/report";
import { loading } from "../../images";
import { getStoryConfig } from "../../config";
import Presentation from "./Presentation";
import PresentationError from "./PresentationError";

const ViewReport = props => {
  const {
    reportIsLoading,
    reportError,
    userId,
    location,
    client,
    reportClient,
    getReport,
    date,
    report,
    meta,
    title
  } = props;
  useEffect(() => {
    const queryString = location.search.slice(1);
    const urlParams = new URLSearchParams(queryString);
    const story = urlParams.get("story")
      ? urlParams.get("story").toLowerCase()
      : "";
    const { dataSet } = getStoryConfig(story);
    getReport(queryString, userId, date[dataSet], client);
  }, []);
  const urlParams = new URLSearchParams(location.search);
  const story = urlParams.get("story")
    ? urlParams.get("story").toLowerCase()
    : "";
  const storyConfig = getStoryConfig(story);
  if (reportIsLoading) {
    return (
      <Fragment>
        <Favicon url={["favicon0.ico", "favicon1.ico", "favicon2.ico"]} />
        <Grid container justifyContent="center">
          <Grid item>
            <Box textAlign="center" sx={{ mt: 3 }}>
              <img style={{ width: "50%" }} alt="Interrodata" src={loading} />
            </Box>
          </Grid>
          <Grid item>
            <Typography align="center" gutterBottom>
              You can run{" "}
              <Link
                href={`/reports-reviews/new-${storyConfig.newLink}`}
                target="_blank"
                rel="noreferrer"
                underline="hover"
              >
                another report
              </Link>{" "}
              or view{" "}
              <Link
                href={`/reports-reviews/${storyConfig.link}`}
                target="_blank"
                rel="noreferrer"
                underline="hover"
              >
                your recent reports
              </Link>{" "}
              while you are waiting
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
  if (reportError.message) {
    return (
      <Grid container justifyContent="center">
        <Grid item md={10} sm={12}>
          <Favicon url="favicon.ico" />
          <PresentationError errorMessage={reportError.message} story={story} />
        </Grid>
      </Grid>
    );
  }
  return (
    <Fragment>
      <Favicon url="favicon.ico" />
      <Grid container justifyContent="center" alignItems="center">
        <Presentation
          report={report}
          meta={meta}
          client={reportClient}
          userId={userId}
          story={story}
          title={title}
        />
      </Grid>
    </Fragment>
  );
};

ViewReport.propTypes = {
  reportIsLoading: PropTypes.bool,
  reportError: PropTypes.shape(),
  getReport: PropTypes.func,
  userId: PropTypes.string,
  date: PropTypes.shape(),
  location: PropTypes.shape(),
  client: PropTypes.string,
  reportClient: PropTypes.string,
  report: PropTypes.shape(),
  meta: PropTypes.shape(),
  title: PropTypes.string
};

ViewReport.defaultProps = {
  reportIsLoading: true,
  reportError: {},
  getReport: () => {},
  userId: "",
  date: {},
  location: {},
  client: "",
  reportClient: "",
  report: {},
  meta: {},
  title: ""
};

const mapStateToProps = state => ({
  report: state.report.reportContent,
  meta: state.report.reportMeta,
  title: state.report.reportTitle,
  reportIsLoading: state.report.isLoading,
  reportError: state.report.error,
  reportClient: state.report.reportClient,
  userId: state.user.user.id,
  client: state.user.user.client,
  date: state.data.date
});

const mapDispatchToProps = dispatch => ({
  getReport: (queryString, userId, date, client) => {
    if (process.env.REACT_APP_DEV_ENV === "local") {
      dispatch(fetchLocalReport());
    } else {
      dispatch(fetchReport(queryString, userId, date, client));
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewReport));
