// presentation
export const DOWNLOAD_REPORT_REQUEST = "DOWNLOAD_REPORT_REQUEST";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";
export const DOWNLOAD_REPORT_ERROR = "DOWNLOAD_REPORT_ERROR";

// report
export const LOAD_REPORT_REQUEST = "LOAD_REPORT_REQUEST";
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";
export const LOAD_REPORT_ERROR = "LOAD_REPORT_ERROR";
export const ADD_USER_TRACKING_REQUEST = "ADD_USER_TRACKING_REQUEST";
export const ADD_USER_TRACKING_SUCCESS = "ADD_USER_TRACKING_SUCCESS";
export const ADD_USER_TRACKING_ERROR = "ADD_USER_TRACKING_ERROR";
export const GET_MY_RECENT_REPORTS_REQUEST = "GET_MY_RECENT_REPORTS_REQUEST";
export const GET_MY_RECENT_REPORTS_SUCCESS = "GET_MY_RECENT_REPORTS_SUCCESS";
export const GET_MY_RECENT_REPORTS_ERROR = "GET_MY_RECENT_REPORTS_ERROR";
export const GET_MY_FAVOURITE_REPORTS_REQUEST =
  "GET_MY_FAVOURITE_REPORTS_REQUEST";
export const GET_MY_FAVOURITE_REPORTS_SUCCESS =
  "GET_MY_FAVOURITE_REPORTS_SUCCESS";
export const GET_MY_FAVOURITE_REPORTS_ERROR = "GET_MY_FAVOURITE_REPORTS_ERROR";
export const ADD_TO_FAVOURITES_REQUEST = "ADD_TO_FAVOURITES_REQUEST";
export const ADD_TO_FAVOURITES_SUCCESS = "ADD_TO_FAVOURITES_SUCCESS";
export const ADD_TO_FAVOURITES_ERROR = "ADD_TO_FAVOURITES_ERROR";
export const REMOVE_FROM_FAVOURITES_REQUEST = "REMOVE_FROM_FAVOURITES_REQUEST";
export const REMOVE_FROM_FAVOURITES_SUCCESS = "REMOVE_FROM_FAVOURITES_SUCCESS";
export const REMOVE_FROM_FAVOURITES_ERROR = "REMOVE_FROM_FAVOURITES_ERROR";

// search
export const ENTER_SEARCH_TERM = "ENTER_SEARCH_TERM";
export const DELETE_LAST_SEARCH_TERM = "DELETE_LAST_SEARCH_TERM";
export const DELETE_TERM = "DELETE_TERM";
export const ENTER_SELECTED_TERM = "ENTER_SELECTED_TERM";
export const ENTER_AUTOCOMPLETE_TERM = "ENTER_AUTOCOMPLETE_TERM";
export const SEARCH_REPORT_HINT = "SEARCH_REPORT_HINT";
export const CLEAR_SEARCH_REPORT_HINT = "CLEAR_SEARCH_REPORT_HINT";
export const FILTER_STATIC_DATA_REQUEST = "FILTER_STATIC_DATA_REQUEST";
export const FILTER_STATIC_DATA_SUCCESS = "FILTER_STATIC_DATA_SUCCESS";
export const FILTER_STATIC_DATA_ERROR = "FILTER_STATIC_DATA_ERROR";
export const REMOVE_STATIC_DATA_FILTER = "REMOVE_STATIC_DATA_FILTER";
export const CLEAR_ALL_SEARCH_DATA = "CLEAR_ALL_SEARCH_DATA";
export const CLEAR_STORY_SEARCH_DATA = "CLEAR_STORY_SEARCH_DATA";
export const GET_CONTEXT_REQUEST = "GET_CONTEXT_REQUEST";
export const GET_CONTEXT_SUCCESS = "GET_CONTEXT_SUCCESS";
export const GET_CONTEXT_ERROR = "GET_CONTEXT_ERROR";
export const REMOVE_CONTEXT = "REMOVE_CONTEXT";

// data
export const FETCH_STATIC_DATA_REQUEST = "FETCH_STATIC_DATA_REQUEST";
export const FETCH_STATIC_DATA_SUCCESS = "FETCH_STATIC_DATA_SUCCESS";
export const FETCH_STATIC_DATA_ERROR = "FETCH_STATIC_DATA_ERROR";
export const CLEAR_STATIC_DATA_CACHE = "CLEAR_STATIC_DATA_CACHE";

// user
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const UNBLOCK_USER_REQUEST = "UNBLOCK_USER_REQUEST";
export const UNBLOCK_USER_SUCCESS = "UNBLOCK_USER_SUCCESS";
export const UNBLOCK_USER_ERROR = "UNBLOCK_USER_ERROR";
export const DISABLE_USER_REQUEST = "DISABLE_USER_REQUEST";
export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";
export const DISABLE_USER_ERROR = "DISABLE_USER_ERROR";
export const ENABLE_USER_REQUEST = "ENABLE_USER_REQUEST";
export const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS";
export const ENABLE_USER_ERROR = "ENABLE_USER_ERROR";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const CLEAR_USER_MESSAGES = "CLEAR_USER_MESSAGES";
export const LOGOUT = "LOGOUT";
export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_ERROR = "GET_DEPARTMENTS_ERROR";
export const ADD_DEPARTMENT_REQUEST = "ADD_DEPARTMENT_REQUEST";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_ERROR = "ADD_DEPARTMENT_ERROR";
export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_ERROR = "GET_CLIENTS_ERROR";

// notification
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// subscription
export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_ERROR = "ADD_CONTACT_ERROR";
export const REMOVE_CONTACT_REQUEST = "REMOVE_CONTACT_REQUEST";
export const REMOVE_CONTACT_SUCCESS = "REMOVE_CONTACT_SUCCESS";
export const REMOVE_CONTACT_ERROR = "REMOVE_CONTACT_ERROR";
export const SUBSCRIBE_REQUEST = "SUBSCRIBE_REQUEST";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";
export const UNSUBSCRIBE_REQUEST = "UNSUBSCRIBE_REQUEST";
export const UNSUBSCRIBE_SUCCESS = "UNSUBSCRIBE_SUCCESS";
export const UNSUBSCRIBE_ERROR = "UNSUBSCRIBE_ERROR";
export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_ERROR = "GET_SUBSCRIPTION_ERROR";
