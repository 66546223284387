import html2pdf from "html2pdf.js";
import {
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR
} from "../constants/actionConstants";
import { addNotification } from "./notification";
import logger from "../logger";

function downloadReportRequest() {
  return {
    type: DOWNLOAD_REPORT_REQUEST
  };
}

function downloadReportSuccess() {
  return {
    type: DOWNLOAD_REPORT_SUCCESS
  };
}

function downloadReportError(error) {
  return {
    type: DOWNLOAD_REPORT_ERROR,
    error
  };
}

export const downloadReport =
  (html, height, width, filename, story, userId, client) => dispatch => {
    dispatch(downloadReportRequest());
    const options = {
      filename: `${filename}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2, height, width },
      jsPDF: { unit: "px", format: [height, width], orientation: "landscape" }
    };
    try {
      if (html === null) {
        throw new Error("Unable to download report");
      }
      html2pdf().set(options).from(html).save();
      logger.info({
        date: new Date().toISOString(),
        action: "DOWNLOAD_REPORT",
        // report_id: reportId, // TODO
        user_id: userId,
        story,
        client,
        product: "ida"
      });
      return dispatch(downloadReportSuccess());
    } catch (err) {
      dispatch(
        addNotification("There was an error downloading the file", "error")
      );
      return dispatch(downloadReportError(err.message));
    }
  };

export default null;
