import React from "react";
import PropTypes from "prop-types";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import { scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Typography } from "@mui/material";

const BarChart = props => {
  const { data, xVar, yVar, height, width, margin, colours } = props;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true
  });
  let tooltipTimeout;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const x = d => d[xVar];
  const y = d => d[yVar];
  const xScale = scaleBand({
    range: [xMax, 0],
    domain: data.map(x),
    padding: 0.4
  });
  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: [Math.min(...data.map(y), 0), Math.max(...data.map(y))]
  });
  const colorScale = scaleOrdinal({
    domain: data.map(x),
    range: colours
  });
  const isNegative = Math.min(...data.map(y), 0) < 0;
  return (
    <svg width={width} height={height} overflow="visible" ref={containerRef}>
      {data.map(d => {
        const barHeight = isNegative
          ? Math.abs(yScale(0) - yScale(y(d)))
          : yMax - yScale(y(d));
        return (
          <Group key={d[xVar]} top={margin.top}>
            <Bar
              x={xScale(x(d))}
              y={
                isNegative
                  ? (y(d) < 0 && yMax / 2) || yMax / 2 - barHeight
                  : yMax - barHeight
              }
              height={barHeight}
              width={xScale.bandwidth()}
              fill={colorScale(x(d))}
              onMouseLeave={() => {
                tooltipTimeout = window.setTimeout(() => {
                  hideTooltip();
                }, 300);
              }}
              onMouseMove={event => {
                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                const eventSvgCoords = localPoint(event);
                showTooltip({
                  tooltipData: d,
                  tooltipTop: eventSvgCoords.y,
                  tooltipLeft: eventSvgCoords.x
                });
              }}
            />
            <text
              x={xScale(x(d))}
              y={
                isNegative
                  ? (y(d) < 0 && yMax / 2) || yMax / 2 - barHeight
                  : yMax - barHeight
              }
              fontSize={8}
              dx={xScale.bandwidth() / 2}
              dy={-5}
              textAnchor="middle"
            >
              {`${y(d)}%`}
            </text>
            <AxisBottom
              top={
                isNegative
                  ? yMax / 2 +
                    Math.abs(yScale(0) - yScale(Math.min(...data.map(y))))
                  : yScale(0)
              }
              scale={xScale}
              hideTicks
              tickLabelProps={(t, i) => ({
                fontSize: 7,
                textAnchor: "middle",
                dy: i % 2 !== 0 ? i * 3.5 : -7
              })}
            />
            {tooltipOpen && tooltipData && (
              <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
                <Typography variant="subtitle3">{tooltipData.name}</Typography>
                <Typography variant="caption">{tooltipData.value}%</Typography>
              </TooltipInPortal>
            )}
          </Group>
        );
      })}
    </svg>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  xVar: PropTypes.string,
  yVar: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  margin: PropTypes.shape(),
  colours: PropTypes.arrayOf(PropTypes.string)
};

BarChart.defaultProps = {
  data: [],
  xVar: "",
  yVar: "",
  height: 300,
  width: 500,
  margin: { top: 20, bottom: 20, left: 20, right: 20 },
  colours: []
};

export default BarChart;
