import React from "react";
import PropTypes from "prop-types";
import { Typography, ListItem } from "@mui/material";
import DatePicker from "react-datepicker";
import { isSaturday, isSunday } from "date-fns";
import CustomDateInput from "./CustomDateInput";
import { locale, formatWeekNumber } from "../../utils";
import "react-datepicker/dist/react-datepicker.css";
import { InfoIcon } from "../Icons";

const CustomDatepicker = props => {
  const {
    dateError,
    onChangeStart,
    onChangeEnd,
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate,
    selectedStart,
    selectedEnd,
    disabledStart,
    disabledEnd,
    openToDate,
    setCalendarOpen,
    startDate,
    endDate,
    hintText
  } = props;
  return (
    <ListItem disablePadding>
      <DatePicker
        customInput={<CustomDateInput label="from" helperText={dateError} />}
        dateFormat="dd/MM/yyyy"
        locale={locale}
        selected={selectedStart}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showWeekNumbers
        formatWeekNumber={date => formatWeekNumber(date)}
        onChange={onChangeStart}
        filterDate={isSunday}
        // openToDate={openToDate}
        minDate={minStartDate}
        maxDate={maxStartDate}
        placeholderText="From"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={disabledStart}
        popperPlacement="bottom-end"
        onCalendarOpen={() => setCalendarOpen(true)}
        onCalendarClose={() => setCalendarOpen(false)}
      />
      <DatePicker
        customInput={<CustomDateInput label="to" helperText={dateError} />}
        dateFormat="dd/MM/yyyy"
        locale={locale}
        selected={selectedEnd}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        showWeekNumbers
        formatWeekNumber={date => formatWeekNumber(date)}
        onChange={onChangeEnd}
        filterDate={isSaturday}
        openToDate={openToDate}
        minDate={minEndDate}
        maxDate={maxEndDate}
        placeholderText="To"
        disabled={disabledEnd}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement="bottom-end"
        onCalendarOpen={() => setCalendarOpen(true)}
        onCalendarClose={() => setCalendarOpen(false)}
      />
      <InfoIcon color="primary" sx={{ m: 1 }} />
      <Typography variant="subtitle2" color="primary">
        {hintText}
      </Typography>
    </ListItem>
  );
};

CustomDatepicker.propTypes = {
  dateError: PropTypes.string,
  onChangeStart: PropTypes.func,
  onChangeEnd: PropTypes.func,
  minStartDate: PropTypes.shape(),
  maxStartDate: PropTypes.shape(),
  minEndDate: PropTypes.shape(),
  maxEndDate: PropTypes.shape(),
  selectedStart: PropTypes.shape(),
  selectedEnd: PropTypes.shape(),
  disabledStart: PropTypes.bool,
  disabledEnd: PropTypes.bool,
  openToDate: PropTypes.shape(),
  startDate: PropTypes.shape(),
  endDate: PropTypes.shape(),
  setCalendarOpen: PropTypes.func,
  hintText: PropTypes.string
};

CustomDatepicker.defaultProps = {
  dateError: "",
  onChangeStart: () => {},
  onChangeEnd: () => {},
  minStartDate: {},
  maxStartDate: {},
  minEndDate: {},
  maxEndDate: {},
  selectedStart: {},
  selectedEnd: {},
  disabledStart: false,
  disabledEnd: false,
  openToDate: {},
  startDate: {},
  endDate: {},
  setCalendarOpen: () => {},
  hintText: ""
};

export default CustomDatepicker;
