import React, { useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Paper, Box, Grid, Typography, Button } from "@mui/material";
import { DownloadIcon } from "../Icons";
import { interrodata } from "../../images";
import { downloadReport } from "../../actions/presentation";
import theme from "./theme";
import ErrorBoundary from "./ErrorBoundary";
import Overview from "./Overview";
import Parent from "./Parent";
import PerformanceContext from "./PerformanceContext";
import Quarter from "./Quarter";
import BusinessInsights from "./BusinessInsights";
import Trends from "./Trends";
import KeyAttribute from "./KeyAttribute";

const Presentation = props => {
  const { report, meta, generatePDF, story, userId, client, title } = props;
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = createRef();
  useEffect(() => {
    if (!_.isEmpty(report) && !_.isEmpty(meta)) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  }, [ref]);
  if (_.isEmpty(report)) {
    return (
      <div className="Presentation">
        <Paper elevation={0} sx={{ p: 2, height: 1, width: 1 }}>
          <Box textAlign="center">No data.</Box>
        </Paper>
      </div>
    );
  }
  const html = document.getElementById("dashboard");
  const target = _.startCase(
    _.toLower(_.values(meta.constraints.target).join(", "))
  );
  const context = _.startCase(
    _.toLower(_.values(meta.constraints.context).join(", "))
  );
  return (
    <div className="Presentation">
      <Button
        startIcon={<DownloadIcon />}
        onClick={() =>
          generatePDF(html, height, width, title, story, userId, client)
        }
      >
        Generate PDF
      </Button>
      <Paper
        id="dashboard"
        className="dashboard"
        ref={ref}
        sx={{ p: 2, pb: 3, height: 1, width: 1, bgcolor: theme.light }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 2 }}
        >
          <Grid item md={6}>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="body2" gutterBottom>
              Time period: {meta.constraints.periods[1]} vs{" "}
              {meta.constraints.periods[0]}
            </Typography>
          </Grid>
          <Grid item md={4} container alignItems="center" columnSpacing={2}>
            <Grid item md={6} />
            <Grid item md={6}>
              <Typography variant="body2">Powered by</Typography>
            </Grid>
            <Grid item md={6}>
              <Box textAlign="right">
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="client"
                  src="snappy-logo.png"
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box textAlign="right">
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="interrodata"
                  src={interrodata}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item md={12}>
            <Typography variant="dashboardTitle">
              {target} {context} Performance
            </Typography>
          </Grid>
          <Grid item md={6}>
            <ErrorBoundary>
              <Overview
                data={report.overview.data[0]}
                nl={report.overview.nl}
                meta={meta}
                theme={theme}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item md={2}>
            <ErrorBoundary>
              <Parent
                data={report.parent.data}
                nl={report.parent.nl}
                theme={theme}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item md={2}>
            <ErrorBoundary>
              <KeyAttribute
                data={report.share_attrs.data}
                nl={report.share_attrs.nl}
                theme={theme}
                target={target}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item md={2}>
            <ErrorBoundary>
              <Quarter
                data={report.quarterly_context.data[0]}
                nl={report.quarterly_context.nl}
                theme={theme}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item md={12} container alignItems="flex-end" spacing={2}>
            <Grid item md={5}>
              <Typography variant="dashboardTitle">
                {target} Performance in {context}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant="dashboardTitle">Trends</Typography>
            </Grid>
            <Grid item md={5}>
              <Typography variant="dashboardTitle">
                What is going on within my business?
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={5}>
            <ErrorBoundary>
              <PerformanceContext
                data={{
                  overview_context: report.overview_context.data[0],
                  heatmap: report.heatmap.data
                }}
                nl={{
                  overview_context: report.overview_context.nl,
                  heatmap: report.heatmap.nl,
                  basket_context: report.basket_context.nl[0]
                }}
                theme={theme}
                target={target}
                context={context}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item md={2}>
            <ErrorBoundary>
              <Trends
                data={report.trend_section.data}
                nl={report.trend_section.nl}
                theme={theme}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item md={5}>
            <ErrorBoundary>
              <BusinessInsights
                data={{
                  child: report.child && report.child.data[0],
                  grand_child: report.grand_child && report.grand_child.data[0],
                  wordweb: report.wordweb.data
                }}
                nl={{
                  child: report.child.nl,
                  grand_child: report.grand_child.nl,
                  wordweb: report.wordweb.nl
                }}
                theme={theme}
              />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  generatePDF: (html, height, width, filename, story, userId, client) =>
    dispatch(
      downloadReport(html, height, width, filename, story, userId, client)
    )
});

Presentation.propTypes = {
  report: PropTypes.shape(),
  meta: PropTypes.shape(),
  generatePDF: PropTypes.func,
  story: PropTypes.string,
  userId: PropTypes.string,
  client: PropTypes.string,
  title: PropTypes.string
};

Presentation.defaultProps = {
  report: {},
  meta: {},
  generatePDF: () => {},
  story: "",
  userId: "",
  client: "",
  title: ""
};

export { Presentation as PresentationComponent };
export default connect(null, mapDispatchToProps)(Presentation);
