import React from "react";
import PropTypes from "prop-types";
import { scaleLog } from "@visx/scale";
import { Wordcloud } from "@visx/wordcloud";
import { Tooltip } from "@mui/material";

const WordWeb = props => {
  const { data, height, width, colours } = props;
  const min = Math.min(...data.map(w => w.value));
  const max = Math.max(...data.map(w => w.value));
  const fontScale = scaleLog({
    domain: [min, max * Math.log(max / min)],
    range: [1, 26]
  });
  const MAX_LENGTH = 40;
  return (
    <Wordcloud
      words={data.map(i => ({
        ...i,
        fullText: i.text,
        text:
          i.text.length > MAX_LENGTH
            ? `${i.text.slice(0, MAX_LENGTH - 3)}...`
            : i.text
      }))}
      width={width}
      height={height}
      fontSize={datum => fontScale(datum.value)}
      font="Roboto Condensed, Impact"
      fontWeight="bold"
      spiral="rectangular"
      rotate={0}
      random={() => 0.5}
    >
      {cloudWords =>
        cloudWords.map((w, i) => (
          <Tooltip
            key={w.fullText}
            title={w.fullText.length > MAX_LENGTH ? w.fullText : ""}
          >
            <text
              fill={colours[i % colours.length]}
              textAnchor="middle"
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </text>
          </Tooltip>
        ))
      }
    </Wordcloud>
  );
};

WordWeb.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  height: PropTypes.number,
  width: PropTypes.number,
  colours: PropTypes.arrayOf(PropTypes.string)
};

WordWeb.defaultProps = {
  data: [],
  height: 300,
  width: 500,
  colours: []
};

export default WordWeb;
