import {
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR
} from "../constants/actionConstants";

const presentation = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        downloadReady: false,
        isLoading: true
      };
    case DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        error: "",
        downloadReady: true,
        isLoading: false
      };
    case DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        error: action.error,
        downloadReady: false,
        isLoading: false
      };
    default:
      return state;
  }
};

export default presentation;
