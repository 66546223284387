import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { robot } from "../../images";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Card raised sx={{ height: 1, width: 1 }}>
          <CardContent>
            <Stack alignItems="center">
              <img
                alt="robot"
                src={robot}
                style={{
                  transform: "scale(0.6)"
                }}
              />
              <Typography
                variant="subtitle2"
                color="orange.main"
                align="center"
              >
                Oops, something went wrong :(
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.shape()
};

ErrorBoundary.defaultProps = {
  children: {}
};

export default ErrorBoundary;
