import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid, Typography, Box } from "@mui/material";

const styles = {
  update: { bgcolor: "#f7f3f2", borderRadius: 1.5 },
  header: { p: 1, borderBottom: "1px solid white" },
  source: { color: "#736f6f" }
};

const DataUpdate = props => {
  const { date, client } = props;
  return (
    <Box textAlign="center">
      <Grid container sx={styles.update}>
        <Grid item xs={12} sx={styles.header}>
          <Typography variant="subtitle2">Data available to w/e</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-evenly"
          sx={{ p: 1 }}
        >
          <Grid item md={4}>
            <Typography variant="subtitle2" fontSize={12}>
              {date.custom}
            </Typography>
            <Typography variant="subtitle3" sx={styles.source}>
              Custom ({_.startCase(client)})
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

DataUpdate.propTypes = {
  date: PropTypes.shape(),
  client: PropTypes.string
};

DataUpdate.defaultProps = {
  date: {},
  client: ""
};

export default DataUpdate;
