import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Card, CardContent, Typography } from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { StackedBarChart } from "./Charts";

const getData = (data, target) => {
  const mappedData = [];
  _.forEach(data, (val, key) => {
    mappedData.push({
      type: key,
      [target]: val.target,
      Other: val.other,
      shareChange: val.target_share_change
    });
  });
  return mappedData;
};

const KeyAttribute = props => {
  const { data, nl, target, theme } = props;
  const chartData = getData(data, target);
  return (
    <Card raised sx={{ height: 1, width: 1, bgcolor: theme.secondary }}>
      <CardContent sx={{ height: 110, bgcolor: "white" }}>
        <ParentSize>
          {({ width, height }) => (
            <StackedBarChart
              data={chartData}
              yVar="type"
              height={height}
              width={width}
              margin={{
                top: 0,
                bottom: 25,
                left:
                  Math.max(...chartData.map(i => i.type.length)) > 25
                    ? 100
                    : 60,
                right: 0
              }}
              colour={[theme.primary, theme.lightgrey]}
              moreInfo="shareChange"
            />
          )}
        </ParentSize>
      </CardContent>
      <CardContent>
        <Typography variant="dashboardBody">{nl[0]}</Typography>
      </CardContent>
    </Card>
  );
};

KeyAttribute.propTypes = {
  data: PropTypes.shape(),
  nl: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.shape(),
  target: PropTypes.string
};

KeyAttribute.defaultProps = {
  data: {},
  nl: [],
  theme: {},
  target: ""
};

export default KeyAttribute;
