import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Card, CardContent, Typography } from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { Waterfall } from "./Charts";

const getData = data => {
  const mappedData = [];
  _.forEach(data, (val, key) => {
    mappedData.push({ period: key, ...val });
  });
  return mappedData;
};

const Quarter = props => {
  const { data, nl, theme } = props;
  const chartData = getData(data);
  return (
    <Card raised sx={{ height: 1, width: 1, bgcolor: theme.secondary }}>
      <CardContent sx={{ height: 110, bgcolor: "white" }}>
        <ParentSize>
          {({ width, height }) => (
            <Waterfall
              data={chartData}
              xVar="period"
              yVar1="end"
              yVar2="start"
              height={height}
              width={width}
              margin={{ top: 0, bottom: 15, left: 0, right: 0 }}
              colour={theme.primary}
            />
          )}
        </ParentSize>
      </CardContent>
      <CardContent>
        <Typography variant="dashboardBody">{nl[0]}</Typography>
      </CardContent>
    </Card>
  );
};

Quarter.propTypes = {
  data: PropTypes.shape(),
  nl: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.shape()
};

Quarter.defaultProps = {
  data: {},
  nl: [],
  theme: {}
};

export default Quarter;
