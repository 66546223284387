import React from "react";
import PropTypes from "prop-types";
import { Group } from "@visx/group";
import { HeatmapRect } from "@visx/heatmap";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";

const Heatmap = props => {
  const { data, xVar, yVar, zVar, val, height, width, margin, colours } = props;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.bottom - margin.top;
  const x = d => d[xVar];
  const y = d => d[yVar];
  const v = d => d[val];
  const size = Math.max(...data.map(i => y(i).length));
  const xScale = scaleLinear({ domain: [0, data.length], range: [0, xMax] });
  const yScale = scaleLinear({ domain: [0, size], range: [yMax, 0] });
  const zScale = scaleLinear({
    range: colours,
    domain: [0, Math.max(...data.map(i => i[yVar].map(j => j[val])).flat())]
  });
  const xAxisScale = scaleBand({ range: [0, xMax], domain: data.map(x) });
  const yAxisScale = scaleBand({
    range: [0, yMax],
    domain: data.map(i => i[yVar].map(j => j[zVar]))[0]
  });
  const binWidth = xMax / data.length;
  const binHeight = yMax / size;
  return (
    <svg width={width} height={height} overflow="visible">
      <Group left={margin.left} top={margin.top}>
        <HeatmapRect
          data={data}
          bins={d => y(d)}
          count={d => v(d)}
          xScale={d => xScale(d) || 0}
          yScale={d => yScale(d) || 0}
          colorScale={zScale}
          binWidth={binWidth}
          binHeight={binHeight}
        >
          {heatmap =>
            heatmap.map(heatmapBins =>
              heatmapBins.map(bin => (
                <rect
                  key={`${bin.datum[xVar]}-${bin.bin[zVar]}`}
                  width={bin.width}
                  height={bin.height}
                  x={bin.x}
                  y={bin.y - bin.height}
                  fill={bin.color}
                />
              ))
            )
          }
        </HeatmapRect>
        <AxisBottom
          scale={xAxisScale}
          top={yMax}
          hideTicks
          hideAxisLine
          tickFormat={i => i.slice(0, 3)}
          tickLabelProps={() => ({ fontSize: 7, textAnchor: "middle", dy: -5 })}
        />
        <AxisLeft
          scale={yAxisScale}
          hideTicks
          hideAxisLine
          tickLabelProps={() => ({
            fontSize: 7,
            textAnchor: "end",
            dy: 4,
            dx: 2
          })}
        />
      </Group>
    </svg>
  );
};

Heatmap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  xVar: PropTypes.string,
  yVar: PropTypes.string,
  zVar: PropTypes.string,
  val: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  margin: PropTypes.shape(),
  colours: PropTypes.arrayOf(PropTypes.string)
};

Heatmap.defaultProps = {
  data: [],
  xVar: "",
  yVar: "",
  zVar: "",
  val: "",
  height: 300,
  width: 500,
  margin: { top: 20, bottom: 20, left: 20, right: 20 },
  colours: []
};

export default Heatmap;
