import React from "react";
import { SvgIcon } from "@mui/material";

export const ReportsReviewsIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-1.601 .602)">
      <g transform="translate(5.751 1.425)">
        <path
          d="M16.6-2H2.8C2-2,1.4-1.4,1.4-0.7v2.2h-2.2c-0.7,0-1.3,0.5-1.3,1.3v17.8c0,0.7,0.6,1.3,1.3,1.3H13
					c0.7,0,1.3-0.6,1.3-1.3v-2.2h2.2c0.7,0,1.3-0.6,1.3-1.3V-0.7C18-1.4,17.4-2,16.6-2z M13.3,20.5c0,0.1-0.1,0.2-0.2,0.2l0,0H-0.9
					c-0.1,0-0.2-0.1-0.2-0.2l0,0V2.9c0-0.1,0.1-0.2,0.2-0.2l0,0h2.2v14.4c0,0.7,0.6,1.3,1.3,1.3h10.5v2.1H13.3z M16.8,17.1
					c0,0.1-0.1,0.2-0.3,0.2l0,0H2.8c-0.1,0-0.3-0.1-0.3-0.2l0,0V-0.7c0-0.1,0.1-0.2,0.3-0.2l0,0h13.8c0.1,0,0.3,0.1,0.3,0.2l0,0
					L16.8,17.1L16.8,17.1z"
        />
        <path
          d="M13.4,10.8h-7c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5
					C13.9,11,13.7,10.8,13.4,10.8L13.4,10.8z"
        />
        <path
          d="M13.4,14.1h-7c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5
					C13.9,14.3,13.7,14.1,13.4,14.1z"
        />
        <path
          d="M13.4,7.5h-7C6.1,7.5,5.9,7.7,5.9,8c0,0.3,0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5
					C13.9,7.7,13.7,7.5,13.4,7.5C13.5,7.5,13.4,7.5,13.4,7.5z"
        />
        <path
          d="M6.6,5.2h0.8c0.2,0,0.2-0.1,0.2-0.1l0,0V3.7c0-0.1-0.1-0.2-0.2-0.2l0,0H6.6c-0.1,0-0.2,0.1-0.2,0.2l0,0V5
					C6.5,5.1,6.5,5.2,6.6,5.2z"
        />
        <path
          d="M9.1,5.1h0.8c0.1,0,0.2-0.1,0.2-0.2l0,0V2.5c0-0.1-0.1-0.2-0.2-0.2l0,0H9.1C9,2.3,8.9,2.4,8.9,2.5l0,0v2.3
					C8.9,5,9,5.1,9.1,5.1z"
        />
        <path
          d="M11.7,4.5h0.8c0.1,0,0.2-0.1,0.2-0.2l0,0V1c0-0.1-0.1-0.2-0.2-0.2h-0.8c-0.1,0-0.2,0.1-0.2,0.2l0,0v3.3
					C11.5,4.4,11.6,4.5,11.7,4.5z"
        />
      </g>
    </g>
  </SvgIcon>
);

export const PerformanceIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M2.4,4.8H0v16.8C0,22.9,1.1,24,2.4,24l0,0h16.8v-2.4H2.4V4.8z M21.6,0H7.2
      C5.9,0,4.8,1.1,4.8,2.4v14.4c0,1.3,1.1,2.4,2.4,2.4l0,0h14.4c1.3,0,2.4-1.1,2.4-2.4l0,0V2.4C24,1.1,22.9,0,21.6,0L21.6,0z
      M21.6,16.8H7.2V2.4h14.4V16.8z M9.6,8.3h9.6v2.4H9.6V8.3z M9.6,12h4.8v2.4H9.6V12z M9.6,4.8h9.6v2.4H9.6V4.8z"
    />
  </SvgIcon>
);

export const EditIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-7.199 -7.253)">
      <path
        d="M11.5,30.7c-2.1,0-3.7-1.7-3.7-3.7V13.5c0-2.1,1.7-3.7,3.7-3.7h4.3c0.6,0,1.1,0.5,1.1,1.1
        c0,0.6-0.5,1.1-1.1,1.1h-4.3c-0.9,0-1.6,0.7-1.6,1.6l0,0V27c0,0.9,0.7,1.6,1.6,1.6H25c0.9,0,1.6-0.7,1.6-1.6c0,0,0,0,0,0v-4.3
        c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1V27c0,2.1-1.7,3.7-3.7,3.7H11.5z M14.5,23.6c-0.3-0.4-0.4-0.9-0.3-1.4l0.9-3
        c0-0.2,0.1-0.3,0.3-0.5l10-10c1.2-1.2,3.2-1.2,4.4,0c1.2,1.2,1.2,3.2,0,4.4l-10,10c-0.1,0.1-0.3,0.2-0.5,0.3l-3.1,0.9l-0.4,0.1l0,0
        C15.3,24.3,14.8,24,14.5,23.6z M17.1,20.1l-0.5,1.8l1.9-0.5l8.3-8.3l-1.3-1.3L17.1,20.1z M28.2,11.6L28.2,11.6c0.4-0.4,0.4-1,0-1.3
        c-0.4-0.4-1-0.4-1.3,0h0L28.2,11.6z"
      />
    </g>
  </SvgIcon>
);

export const PreviousIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M12.01,0C18.64,0.01,24.01,5.39,24,12.01S18.61,24.01,11.99,24C5.37,23.99,0.01,18.63,0,12.01C-0.01,5.39,5.36,0.01,11.99,0
      C12,0,12,0,12.01,0z M12.01,21.88c5.46,0,9.89-4.43,9.89-9.89s-4.43-9.89-9.89-9.89s-9.89,4.43-9.89,9.89
      C2.13,17.45,6.55,21.87,12.01,21.88z M8.35,12.01l4.96,4.96l1.51-1.51l-3.47-3.45l3.45-3.47l-1.51-1.51L8.35,12.01z"
    />
  </SvgIcon>
);

export const NextIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M11.99,24C5.36,23.99-0.01,18.61,0,11.99C0.01,5.36,5.39-0.01,12.01,0C18.63,0.01,23.99,5.37,24,11.99
      c0.01,6.63-5.36,12.01-11.99,12.01C12,24,12,24,11.99,24z M11.99,2.12c-5.46,0-9.89,4.43-9.89,9.89s4.43,9.89,9.89,9.89
      s9.89-4.43,9.89-9.89C21.87,6.55,17.45,2.13,11.99,2.12z M15.65,11.99l-4.96-4.96L9.18,8.54l3.47,3.45L9.2,15.46l1.51,1.51
      L15.65,11.99z"
    />
  </SvgIcon>
);

export const DownloadIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-4 -968.362)">
      <path
        d="M15.95,971.52c-3.47-0.02-6.47,2.45-7.12,5.86c-3.23,0.62-5.35,3.75-4.73,6.98c0.54,2.81,3,4.84,5.86,4.84h12.74
        c2.86,0.06,5.23-2.21,5.29-5.07c0.06-2.76-2.06-5.08-4.82-5.28c0.07-3.98-3.1-7.26-7.07-7.33
        C16.06,971.52,16.01,971.52,15.95,971.52z M15.95,973.08c1.51-0.01,2.97,0.59,4.04,1.67c1.25,1.24,1.85,2.99,1.64,4.74
        c-0.05,0.43,0.25,0.82,0.68,0.87c0.03,0,0.06,0.01,0.09,0.01h0.31c2.01,0.07,3.58,1.75,3.52,3.76c-0.06,1.92-1.6,3.45-3.52,3.52
        H9.97c-2.44,0-4.42-1.98-4.42-4.42c0-2.28,1.74-4.19,4.01-4.4c0.36-0.04,0.65-0.32,0.7-0.68C10.6,975.25,13.05,973.07,15.95,973.08
        z M15.95,977.77c-0.43,0-0.78,0.35-0.78,0.78l0,0v5l-1.55-1.42c-0.32-0.27-0.81-0.25-1.11,0.05c-0.29,0.32-0.27,0.81,0.05,1.11
        l2.86,2.6c0.14,0.14,0.33,0.21,0.53,0.2c0.2,0.02,0.39-0.06,0.53-0.2l2.86-2.6c0.32-0.29,0.34-0.79,0.05-1.11
        c-0.3-0.29-0.78-0.31-1.11-0.05l-1.55,1.41v-5C16.73,978.11,16.38,977.76,15.95,977.77L15.95,977.77z"
      />
    </g>
  </SvgIcon>
);

export const SettingsIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-5.359 -4.57)">
      <path
        d="M28.5,14.59c0.66-0.49,0.85-1.4,0.43-2.11l-2.31-3.89c-0.4-0.74-1.33-1.01-2.07-0.61l-2.03,0.94
        c-0.36-0.24-0.73-0.45-1.11-0.64l-0.21-2.27L21.2,5.9c-0.14-0.76-0.79-1.31-1.56-1.33h-4.51c-0.83,0-1.52,0.64-1.58,1.46l-0.2,2.24
        c-0.38,0.19-0.76,0.4-1.11,0.64l-2.05-0.94l-0.05-0.02c-0.74-0.3-1.59-0.01-2,0.68l-2.24,3.84c-0.42,0.71-0.23,1.62,0.43,2.11
        l1.86,1.32c-0.02,0.22-0.03,0.44-0.03,0.66c-0.01,0.21,0,0.43,0.03,0.64l-1.92,1.32l-0.04,0.03c-0.66,0.49-0.85,1.4-0.43,2.11
        l2.26,3.89c0.19,0.33,0.5,0.59,0.86,0.72c0.4,0.13,0.83,0.09,1.2-0.11l2.04-0.93c0.35,0.24,0.72,0.45,1.1,0.64l0.21,2.27l0.02,0.11
        c0.14,0.75,0.79,1.31,1.56,1.32h4.5c0.83,0,1.52-0.64,1.58-1.46l0.2-2.23c0.38-0.19,0.76-0.4,1.11-0.64l2.06,0.95l0.05,0.02
        c0.74,0.3,1.59,0.01,2-0.68l2.23-3.84c0.42-0.71,0.24-1.62-0.42-2.11l-1.72-1.31c0.02-0.23,0.03-0.45,0.03-0.68
        c0-0.22-0.01-0.44-0.03-0.66l1.85-1.32L28.5,14.59z M24.37,14.93l0.09,0.64c0.06,0.33,0.09,0.67,0.09,1
        c-0.01,0.34-0.04,0.67-0.09,1l-0.09,0.62l2.36,1.79l-1.79,3.08l-2.79-1.29l-0.51,0.42c-0.53,0.41-1.12,0.75-1.74,1l-0.57,0.24
        l-0.26,3.02h-3.56l-0.26-3.02l-0.57-0.25c-0.63-0.27-1.23-0.61-1.77-1.03l-0.5-0.38l-2.77,1.28l-1.78-3.06l2.07-1.41l0.54-0.38
        l-0.09-0.65c-0.06-0.33-0.09-0.67-0.1-1c0.01-0.34,0.04-0.67,0.09-1l0.09-0.64l-2.51-1.79l1.78-3.06l2.79,1.28l0.51-0.42
        c0.53-0.41,1.11-0.75,1.74-1l0.58-0.24l0.26-3.02h3.55l0.26,3.02l0.57,0.25c0.63,0.27,1.23,0.61,1.77,1.03l0.5,0.38l2.76-1.27
        l1.83,3.05L24.37,14.93z"
      />
      <path
        d="M17.36,12.03c-2.51,0-4.54,2.03-4.54,4.54c0,2.51,2.03,4.54,4.54,4.54c2.51,0,4.54-2.03,4.54-4.54
        c0.03-2.48-1.96-4.51-4.44-4.54C17.42,12.03,17.39,12.03,17.36,12.03z M17.36,19c-1.34,0-2.43-1.09-2.43-2.43s1.09-2.43,2.43-2.43
        s2.43,1.09,2.43,2.43c0.01,1.33-1.05,2.41-2.37,2.43C17.39,19,17.38,19,17.36,19z"
      />
    </g>
  </SvgIcon>
);

export const LogoutIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(29.703 33.652) rotate(180)">
      <path
        d="M29.7,21.65c0,0.03,0,0.06,0,0.08c-0.01,0.16-0.06,0.31-0.13,0.45c0,0.01-0.01,0.02-0.02,0.03c-0.08,0.1-0.16,0.2-0.25,0.3
        l-0.01,0.01c-0.12,0.12-0.27,0.28-0.46,0.47l-2.94,2.94c-0.44,0.44-1.16,0.44-1.6,0s-0.44-1.16,0-1.6c0,0,0.75-0.75,1.55-1.55
        H15.57c-0.63,0-1.13-0.51-1.13-1.13s0.51-1.13,1.13-1.13h10.26l-1.55-1.55c-0.46-0.42-0.49-1.14-0.07-1.6
        c0.42-0.46,1.14-0.49,1.6-0.07c0.03,0.02,0.05,0.05,0.07,0.07l3.48,3.48C29.58,21.06,29.7,21.35,29.7,21.65z"
      />
      <path
        d="M22.23,27.4c0.42,0.47,0.38,1.18-0.09,1.6c0,0,0,0,0,0c-4.06,3.63-10.3,3.28-13.93-0.78S4.93,17.93,8.99,14.3
        c3.74-3.35,9.4-3.35,13.15,0c0.48,0.41,0.54,1.12,0.13,1.6c-0.41,0.48-1.12,0.54-1.6,0.13c-0.01-0.01-0.03-0.02-0.04-0.04
        c-3.13-2.8-7.93-2.53-10.73,0.6s-2.53,7.93,0.6,10.73c2.88,2.58,7.25,2.58,10.13,0C21.1,26.9,21.81,26.94,22.23,27.4
        C22.23,27.4,22.23,27.4,22.23,27.4z"
      />
    </g>
  </SvgIcon>
);

export const ChangePasswordIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-214.1 -349)">
      <path
        d="M216.01,361.82l-0.73,1.02c-0.34,0.47-0.24,1.12,0.23,1.46c0,0,0,0,0,0c0.18,0.13,0.4,0.21,0.63,0.21
        c0.34,0,0.66-0.17,0.86-0.44l0.76-1.02l0.76,1.02c0.2,0.28,0.52,0.44,0.86,0.44c0.22-0.01,0.44-0.08,0.63-0.21
        c0.47-0.34,0.57-0.99,0.24-1.46c0,0,0,0,0,0l-0.76-1.02l1.2-0.39c0.55-0.19,0.85-0.78,0.66-1.34c-0.18-0.54-0.77-0.84-1.32-0.67
        l-1.25,0.39v-1.28c0-0.58-0.47-1.04-1.04-1.04s-1.04,0.47-1.04,1.04v1.28l-1.2-0.39c-0.55-0.19-1.15,0.11-1.34,0.66
        c-0.19,0.55,0.11,1.15,0.66,1.34c0.01,0,0.01,0,0.02,0.01L216.01,361.82z"
      />
      <path
        d="M236.69,359.42l-1.2,0.39v-1.28c0-0.58-0.47-1.04-1.04-1.04s-1.04,0.47-1.04,1.04v1.28l-1.2-0.39
        c-0.56-0.17-1.15,0.14-1.32,0.69c-0.17,0.55,0.13,1.13,0.67,1.32l1.2,0.39l-0.79,1.02c-0.34,0.47-0.24,1.12,0.23,1.46c0,0,0,0,0,0
        c0.18,0.13,0.4,0.21,0.63,0.21c0.34,0,0.66-0.17,0.86-0.44l0.76-1.02l0.76,1.02c0.2,0.28,0.52,0.44,0.86,0.44
        c0.22-0.01,0.44-0.08,0.63-0.21c0.47-0.34,0.57-0.99,0.24-1.46c0,0,0,0,0,0l-0.75-1.02l1.2-0.39c0.55-0.18,0.85-0.78,0.68-1.33
        C237.84,359.56,237.25,359.27,236.69,359.42z"
      />
      <path
        d="M228.35,359.42l-1.23,0.39v-1.28c0-0.58-0.47-1.04-1.04-1.04s-1.04,0.47-1.04,1.04v1.28l-1.2-0.39
        c-0.55-0.19-1.15,0.11-1.34,0.66c-0.19,0.55,0.11,1.15,0.66,1.34c0.01,0,0.01,0,0.02,0.01l1.2,0.39l-0.76,1.02
        c-0.34,0.47-0.24,1.12,0.23,1.46c0,0,0,0,0,0c0.18,0.13,0.4,0.21,0.63,0.21c0.34,0,0.66-0.17,0.86-0.44l0.76-1.02l0.76,1.02
        c0.2,0.28,0.52,0.44,0.86,0.44c0.22-0.01,0.44-0.08,0.63-0.21c0.47-0.34,0.57-0.99,0.24-1.46c0,0,0,0,0,0l-0.76-1.02l1.2-0.39
        c0.55-0.18,0.85-0.78,0.68-1.33C229.49,359.56,228.91,359.27,228.35,359.42z"
      />
    </g>
  </SvgIcon>
);

export const CreateAccountIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-2 -9.211)">
      <path
        d="M17.75,18.74c1.13-1.03,1.77-2.49,1.76-4.02c0-3.04-2.47-5.51-5.51-5.51c-3.04,0-5.51,2.47-5.51,5.51
        c-0.01,1.53,0.63,2.99,1.76,4.02c-3.73,1.53-6.17,5.16-6.17,9.2c0,0.61,0.49,1.1,1.1,1.1s1.1-0.49,1.1-1.1
        c0-4.26,3.45-7.71,7.71-7.71s7.71,3.45,7.71,7.71c0,0.61,0.49,1.1,1.1,1.1s1.1-0.49,1.1-1.1C23.91,23.9,21.48,20.27,17.75,18.74z
        M10.7,14.72c0-1.82,1.48-3.3,3.3-3.3s3.3,1.48,3.3,3.3s-1.48,3.3-3.3,3.3C12.18,18.02,10.7,16.54,10.7,14.72z"
      />
      <path
        d="M18.4,28.81c0,0.6-0.49,1.09-1.1,1.1H15.1v2.2c0,0.61-0.49,1.1-1.1,1.1c-0.61,0-1.1-0.49-1.1-1.1v-2.2h-2.21
        c-0.61,0-1.1-0.49-1.1-1.1c0-0.61,0.49-1.1,1.1-1.1h2.2V25.5c0-0.61,0.49-1.1,1.1-1.1s1.1,0.49,1.1,1.1l0,0v2.21h2.2
        C17.91,27.71,18.4,28.2,18.4,28.81z"
      />
    </g>
  </SvgIcon>
);

export const UnblockUserIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-1.323 -0.48)">
      <path
        d="M13.32,0.48c-3.27,0-5.92,2.65-5.92,5.92v4.23H6.55c-1.4,0.01-2.53,1.14-2.54,2.54v8.78c0.01,1.4,1.14,2.53,2.54,2.53
        h13.54c1.4-0.01,2.53-1.14,2.53-2.53v-8.78c0-1.4-1.14-2.53-2.53-2.54h-11V6.4c0-2.34,1.89-4.23,4.23-4.23
        c2.34,0,4.23,1.89,4.23,4.23v0.84c-0.02,0.47,0.35,0.86,0.82,0.88s0.86-0.35,0.88-0.82c0-0.02,0-0.04,0-0.06V6.4
        C19.25,3.13,16.59,0.48,13.32,0.48z M6.55,12.32h13.54c0.46-0.01,0.83,0.35,0.84,0.81c0,0.01,0,0.03,0,0.04v8.78
        c0.01,0.45-0.35,0.83-0.8,0.84c-0.01,0-0.03,0-0.04,0H6.55c-0.45,0.01-0.83-0.35-0.84-0.8c0-0.01,0-0.03,0-0.04v-8.78
        c-0.01-0.46,0.35-0.84,0.8-0.85C6.53,12.32,6.54,12.32,6.55,12.32z"
      />
    </g>
  </SvgIcon>
);

export const DisableUserIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M12,0c6.63,0,12,5.37,12,12s-5.37,12-12,12S0,18.63,0,12C0,5.37,5.37,0,12,0z M12,1.48C6.19,1.48,1.48,6.19,1.48,12
      S6.19,22.52,12,22.52c5.81,0,10.52-4.71,10.52-10.52C22.51,6.19,17.81,1.49,12,1.48z"
    />
    <path
      d="M6.15,10.76h11.76c0.19,0.01,0.34,0.16,0.36,0.35v1.77c0.01,0.19-0.14,0.35-0.33,0.35c-0.01,0-0.02,0-0.03,0
      H6.15c-0.24,0-0.41-0.12-0.41-0.35v-1.77C5.76,10.91,5.94,10.75,6.15,10.76z"
    />
  </SvgIcon>
);

export const EnableUserIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M12,0c6.63,0,12,5.37,12,12s-5.37,12-12,12S0,18.63,0,12C0,5.37,5.37,0,12,0z M12,1.48C6.19,1.48,1.48,6.19,1.48,12
      S6.19,22.52,12,22.52c5.81,0,10.52-4.71,10.52-10.52C22.51,6.19,17.81,1.49,12,1.48z"
    />
    <path
      d="M16.84,8.14l-5.85,5.54l-2.43-2.57c-0.4-0.42-1.06-0.43-1.47-0.03s-0.43,1.06-0.03,1.47l0,0l3.15,3.29
      c0.19,0.2,0.45,0.31,0.72,0.31c0.27,0.01,0.53-0.09,0.72-0.27l6.61-6.26c0.4-0.39,0.42-1.03,0.03-1.44c-0.37-0.41-1-0.44-1.4-0.07
      C16.86,8.12,16.85,8.13,16.84,8.14L16.84,8.14z"
    />
  </SvgIcon>
);

export const ResetPasswordIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-7.497 -7.5)">
      <path
        d="M31.49,12.15c-0.06,0.47-0.47,0.83-0.95,0.83c-0.04,0-0.08,0-0.13-0.01l-1.66-0.22c3.72,5.28,2.46,12.58-2.82,16.31
        c-5.28,3.72-12.58,2.46-16.31-2.82S7.17,13.66,12.46,9.94c1.97-1.39,4.33-2.14,6.74-2.14c0.53,0,0.96,0.43,0.96,0.96
        c0,0.53-0.43,0.96-0.96,0.96c0,0,0,0,0,0c-5.41-0.01-9.81,4.38-9.81,9.79s4.38,9.81,9.79,9.81c5.41,0.01,9.81-4.38,9.81-9.79
        c0-2.03-0.62-4.01-1.8-5.67l-0.09,2.1c-0.02,0.51-0.44,0.91-0.95,0.91h-0.04c-0.53-0.02-0.94-0.47-0.91-1l0.2-4.45
        c0-0.01,0-0.03,0.01-0.04c0.01-0.01,0-0.02,0-0.03c0-0.01,0-0.01,0-0.01l0,0c0-0.02,0.01-0.04,0.01-0.06
        c0-0.02,0.01-0.06,0.02-0.08c0.01-0.03,0.01-0.03,0.01-0.04c0-0.01,0.02-0.03,0.02-0.05c0.01-0.02,0.02-0.05,0.03-0.08
        s0.01-0.03,0.02-0.04c0.01-0.01,0.02-0.03,0.04-0.05s0.03-0.04,0.04-0.06l0.01-0.02c0.01-0.01,0.01-0.02,0.02-0.03
        c0.01-0.01,0.03-0.03,0.05-0.04c0.02-0.01,0.03-0.03,0.04-0.04c0.02-0.01,0.03-0.03,0.04-0.04c0.02-0.01,0.04-0.03,0.06-0.04
        l0.04-0.03c0.02-0.01,0.04-0.02,0.05-0.03c0.02-0.01,0.04-0.02,0.07-0.03l0.05-0.02c0.02-0.01,0.04-0.02,0.06-0.02
        c0.02-0.01,0.04-0.01,0.06-0.01l0.06-0.01c0.02,0,0.04-0.01,0.05-0.01c0.02,0,0.04,0,0.06,0c0.02,0,0.03,0,0.04,0s0.03,0,0.04,0.01
        h0.05l4.19,0.56C31.19,11.15,31.56,11.63,31.49,12.15C31.49,12.16,31.49,12.16,31.49,12.15L31.49,12.15z"
      />
    </g>
  </SvgIcon>
);

export const DownArrowIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M20,0H4C1.79,0,0,1.79,0,4v16c0,2.21,1.79,4,4,4h16c2.21,0,4-1.79,4-4V4C24,1.79,22.21,0,20,0z M16.67,13.38l-3.94,3.93
      l-0.08,0.06l-0.02,0.02l-0.08,0.03h-0.04l-0.06,0.02h-0.11c-0.04,0.01-0.07,0.01-0.11,0c-0.03-0.01-0.05-0.02-0.08-0.03h-0.02
      c-0.03-0.01-0.06-0.02-0.08-0.04h-0.02c-0.03-0.02-0.05-0.04-0.08-0.07l-3.92-3.95c-0.21-0.21-0.21-0.56,0.01-0.78
      c0.21-0.21,0.56-0.21,0.78,0l2.98,3V6.68c0.03-0.3,0.3-0.53,0.6-0.5c0.3,0.02,0.53,0.29,0.5,0.59v8.82l2.99-3
      c0.21-0.18,0.53-0.18,0.74,0.01C16.86,12.8,16.87,13.15,16.67,13.38z"
    />
  </SvgIcon>
);

export const UpArrowIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M4,24l16,0c2.21,0,4-1.79,4-4l0-16c0-2.21-1.79-4-4-4L4,0C1.79,0,0,1.79,0,4l0,16C0,22.21,1.79,24,4,24z M7.33,10.62
      l3.94-3.93l0.08-0.06l0.02-0.02l0.08-0.03h0.04l0.06-0.02h0.11c0.04-0.01,0.07-0.01,0.11,0c0.03,0.01,0.05,0.02,0.08,0.03h0.02
      c0.03,0.01,0.06,0.02,0.08,0.04h0.02C12,6.65,12.02,6.67,12.05,6.7l3.92,3.95c0.21,0.21,0.21,0.56-0.01,0.78
      c-0.21,0.21-0.56,0.21-0.78,0l-2.98-3l0,8.89c-0.03,0.3-0.3,0.53-0.6,0.5c-0.3-0.02-0.53-0.29-0.5-0.59l0-8.82l-2.99,3
      c-0.21,0.18-0.53,0.18-0.74-0.01C7.14,11.2,7.13,10.85,7.33,10.62z"
    />
  </SvgIcon>
);

export const NeutralIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M20,0H4C1.8,0,0,1.8,0,4v16c0,2.2,1.8,4,4,4h16c2.2,0,4-1.8,4-4V4C24,1.8,22.2,0,20,0z M18,12.6H6c-0.3,0-0.6-0.3-0.6-0.6
	    s0.3-0.6,0.6-0.6h12c0.3,0,0.6,0.3,0.6,0.6S18.3,12.6,18,12.6z"
    />
  </SvgIcon>
);

export const DeleteIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-3 -1)">
      <path
        d="M22.64,7.55c-0.6,0-1.09,0.49-1.09,1.09l0,0v12.21c-0.07,1.15-1.04,2.03-2.19,1.97h-8.71c-1.15,0.06-2.13-0.82-2.19-1.97
        V8.64c-0.05-0.6-0.58-1.05-1.18-1c-0.53,0.04-0.95,0.47-1,1v12.21c0.07,2.35,2.02,4.2,4.37,4.15h8.71c2.35,0.05,4.3-1.8,4.37-4.16
        V8.64C23.73,8.03,23.24,7.55,22.64,7.55C22.64,7.55,22.64,7.55,22.64,7.55z"
      />
      <path
        d="M23.73,4.27h-4.37V2.09c0-0.6-0.48-1.09-1.09-1.09h-6.54c-0.6,0-1.09,0.49-1.09,1.09v2.18H6.27c-0.6,0-1.09,0.49-1.09,1.09
        s0.49,1.09,1.09,1.09l0,0h17.45c0.6,0,1.09-0.49,1.09-1.09S24.33,4.27,23.73,4.27L23.73,4.27z M12.82,4.27V3.18h4.36v1.09
        L12.82,4.27z"
      />
      <path
        d="M13.91,18.45v-7.64c0-0.6-0.49-1.09-1.09-1.09c-0.6,0-1.09,0.49-1.09,1.09v7.64c0,0.6,0.49,1.09,1.09,1.09
        C13.42,19.54,13.91,19.06,13.91,18.45z"
      />
      <path
        d="M18.27,18.45v-7.64c0-0.6-0.49-1.09-1.09-1.09c-0.6,0-1.09,0.49-1.09,1.09v7.64c0,0.6,0.49,1.09,1.09,1.09
        C17.79,19.54,18.27,19.06,18.27,18.45z"
      />
    </g>
  </SvgIcon>
);

export const InfoIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.7,0,12,0C12,0,12,0,12,0z M13.2,18h-2.4v-7.2h2.4V18z
	    M13.2,8.4h-2.4V6h2.4V8.4z"
    />
  </SvgIcon>
);
