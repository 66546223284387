import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  Typography,
  Button,
  IconButton,
  Box,
  Stack,
  FormHelperText
} from "@mui/material";
import { StarBorderRounded, StarRounded } from "@mui/icons-material";
import { PerformanceIcon } from "../Icons";
import {
  getMyRecentReports,
  getMyFavouriteReports
} from "../../actions/report";
import { sortByDate } from "../../utils";
import { getStoryConfig } from "../../config";
import ReportCard from "./ReportCard";
import { Loader } from "../SharedComponents";
import { productPerformance } from "../../images";

const getListedReports = (reports, story) =>
  reports
    .map(i => {
      const urlParams = new URLSearchParams(i.query);
      const params = Object.fromEntries(urlParams);
      return { ...i, ...params };
    })
    .filter(e => e.story && story.includes(e.story.toLowerCase()))
    .sort((a, b) => sortByDate(a, b));

const ProductPerformanceHistory = props => {
  const [isStarred, setIsStarred] = useState(false);
  const {
    myRecentReports,
    isLoadingMyReports,
    recentError,
    myFavouriteReports,
    isLoadingFavourites,
    favouritesError,
    getRecentReports,
    getFavourites,
    userId,
    story,
    dataDate
  } = props;

  useEffect(() => {
    if (myRecentReports.length < 1 || myFavouriteReports.length < 1) {
      getRecentReports(userId);
      getFavourites(userId);
    }
  }, []);
  useEffect(() => {
    if (
      myRecentReports.length > 0 &&
      myFavouriteReports.length > 0 &&
      getListedReports(myRecentReports, story).length === 0
    ) {
      setIsStarred(true);
    }
  }, [myRecentReports]);

  const listedReports = isStarred ? myFavouriteReports : myRecentReports;
  const storyReports = getListedReports(listedReports, story);
  return (
    <Card>
      <CardHeader
        avatar={<PerformanceIcon fontSize="small" color="secondaryDark" />}
        titleTypographyProps={{ variant: "h5" }}
        title="Product Performance Report"
      />
      {storyReports.length > 0 ? (
        <Fragment>
          <Typography variant="body2" sx={{ px: 2 }}>
            Run a performance report to understand how a distributor or brand is
            performing over time.
          </Typography>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Link to="/reports-reviews/new-product-performance">
                <Button variant="contained" color="primary" disableElevation>
                  New Product Performance Report
                </Button>
              </Link>
              <IconButton onClick={() => setIsStarred(!isStarred)} size="large">
                {isStarred ? (
                  <StarRounded fontSize="large" color="yellow" />
                ) : (
                  <StarBorderRounded fontSize="large" />
                )}
              </IconButton>
            </Grid>
            <Grid container spacing={3}>
              {listedReports
                .map(i => {
                  const urlParams = new URLSearchParams(i.query);
                  const params = Object.fromEntries(urlParams);
                  return { ...i, ...params };
                })
                .filter(e => e.story && story.includes(e.story.toLowerCase()))
                .sort((a, b) => sortByDate(a, b))
                .map(report => {
                  const isFavourite =
                    myFavouriteReports.filter(e => e.query === report.query)
                      .length > 0;
                  const { dataSet } = getStoryConfig(report.story);
                  return (
                    <ReportCard
                      key={report.reportId ? report.reportId : report.query}
                      report={report}
                      isFavourite={isFavourite}
                      userId={userId}
                      dataDate={dataDate[dataSet]}
                    />
                  );
                })}
            </Grid>
          </CardContent>
        </Fragment>
      ) : (
        <CardContent>
          {((isLoadingFavourites || isLoadingMyReports) && <Loader />) || (
            <Stack alignItems="center" spacing={2} sx={{ py: 4 }}>
              <Box sx={{ p: 2 }}>
                <img alt="product-performance" src={productPerformance} />
              </Box>
              <Typography variant="subtitle2" align="center" gutterBottom>
                No reports found!
                <FormHelperText error>
                  {favouritesError && favouritesError}{" "}
                  {recentError && recentError}
                </FormHelperText>
              </Typography>
              <Link to="/reports-reviews/new-product-performance">
                <Button variant="contained" color="primary" disableElevation>
                  New Product Performance Report
                </Button>
              </Link>
            </Stack>
          )}
        </CardContent>
      )}
    </Card>
  );
};

ProductPerformanceHistory.propTypes = {
  getRecentReports: PropTypes.func,
  userId: PropTypes.string,
  myRecentReports: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingMyReports: PropTypes.bool,
  recentError: PropTypes.string,
  getFavourites: PropTypes.func,
  myFavouriteReports: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingFavourites: PropTypes.bool,
  favouritesError: PropTypes.string,
  story: PropTypes.string,
  dataDate: PropTypes.shape()
};

ProductPerformanceHistory.defaultProps = {
  getRecentReports: () => {},
  userId: "",
  myRecentReports: [],
  isLoadingMyReports: false,
  recentError: "",
  getFavourites: () => {},
  myFavouriteReports: [],
  isLoadingFavourites: false,
  favouritesError: "",
  story: "",
  dataDate: {}
};

const mapStateToProps = state => ({
  myRecentReports: state.report.myRecentReports,
  myFavouriteReports: state.report.myFavouriteReports,
  userId: state.user.user.id,
  isLoadingMyReports: state.report.isLoadingMyReports,
  recentError: state.report.myRecentReportsError,
  isLoadingFavourites: state.report.isLoadingFavourites,
  favouritesError: state.report.myFavouriteReportsError,
  dataDate: state.data.date
});

const mapDispatchToProps = dispatch => ({
  getRecentReports: userId => {
    dispatch(getMyRecentReports(userId));
  },
  getFavourites: userId => {
    dispatch(getMyFavouriteReports(userId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPerformanceHistory);
