import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { format, parseISO, differenceInWeeks } from "date-fns";
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Typography,
  Button,
  Box
} from "@mui/material";
import {
  StarBorderRounded,
  StarRounded,
  DateRangeOutlined
} from "@mui/icons-material";
import { addToFavourites, removeFromFavourites } from "../../actions/report";
import { getWeekStart, getWeekEnd, getDateFromPeriod } from "../../utils";

const styles = {
  header: { py: 0, display: "flex", alignItems: "flex-start" },
  period: { bgcolor: "#f7f3f2", mx: -2, mt: 1, px: 2, py: 1 },
  periodTitle: { display: "flex", alignItems: "flex-start", mb: 1, ml: -0.25 },
  actionButton: {
    borderRadius: 0,
    textTransform: "none",
    p: 1.5,
    fontWeight: "normal"
  }
};

const ReportCard = props => {
  const {
    report,
    isFavourite,
    addFavourite,
    removeFavourite,
    userId,
    dataDate
  } = props;
  const isCustomDate = report.period.split(",").length === 4;
  let mainStart;
  let mainEnd;
  let compareStart;
  let compareEnd;
  let periodLength;
  if (isCustomDate) {
    [compareStart, compareEnd, mainStart, mainEnd] = report.period
      .split(",")
      .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)));
    periodLength = differenceInWeeks(mainEnd, mainStart) + 1;
  } else {
    [compareStart, compareEnd, mainStart, mainEnd] = getDateFromPeriod(
      report.period,
      dataDate
    );
  }
  return (
    <Grid item md={4} xs={6}>
      <Link to={`/reports?${report.query}`}>
        <Card raised>
          <CardHeader
            sx={styles.header}
            title={
              <Fragment>
                {report.date
                  ? format(parseISO(report.date), "dd/MM/yy")
                  : format(new Date(), "dd/MM/yy")}
                <IconButton
                  sx={{ mt: -0.5, ml: 0.5 }}
                  onClick={e => {
                    e.preventDefault();
                    if (isFavourite) {
                      removeFavourite(userId, report);
                    } else {
                      addFavourite(userId, report);
                    }
                  }}
                  size="large"
                >
                  {isFavourite ? (
                    <StarRounded color="yellow" />
                  ) : (
                    <StarBorderRounded />
                  )}
                </IconButton>
              </Fragment>
            }
            titleTypographyProps={{
              variant: "subtitle2",
              color: "text.secondary",
              fontWeight: "normal"
            }}
          />
          <CardContent sx={{ py: 0 }}>
            <Typography variant="h7" fontSize={13}>
              Product
            </Typography>
            <Typography variant="subtitle3" gutterBottom fontSize={13}>
              {_.values(
                _.omit(
                  _.omitBy(
                    report,
                    (i, j) => i === "all" || j.startsWith("context")
                  ),
                  [
                    "metric_name",
                    "retailer",
                    "period",
                    "story",
                    "query",
                    "date",
                    "reportId"
                  ]
                )
              ).join(" / ")}
            </Typography>
            <Divider sx={{ my: 1, mx: -1 }} />
            <Typography variant="h7" fontSize={13}>
              Context
            </Typography>
            <Typography variant="subtitle3" gutterBottom fontSize={13}>
              {_.values(_.pickBy(report, (i, j) => j.startsWith("context")))
                .map(i => i.split("=")[1])
                .join(" / ")}
            </Typography>
            <Box sx={styles.period}>
              <Typography
                variant="subtitle2"
                color="orange.dark"
                sx={styles.periodTitle}
                gutterBottom
              >
                <DateRangeOutlined fontSize="small" /> &nbsp;&nbsp;
                {isCustomDate ? `${periodLength} weeks` : report.period}
              </Typography>
              <Typography variant="h7" gutterBottom>
                Period: &nbsp;&nbsp;
                <Typography variant="subtitle3" component="span" gutterBottom>
                  {format(mainStart, "dd/MM/yy")}&nbsp;-&nbsp;
                  {format(mainEnd, "dd/MM/yy")}
                </Typography>
              </Typography>
              <Typography variant="h7" gutterBottom>
                Comp:&nbsp; &nbsp;&nbsp;
                <Typography variant="subtitle3" component="span" gutterBottom>
                  {format(compareStart, "dd/MM/yy")}&nbsp;-&nbsp;
                  {format(compareEnd, "dd/MM/yy")}
                </Typography>
              </Typography>
            </Box>
          </CardContent>
          <CardActions disableSpacing sx={{ p: 0 }}>
            <Button
              variant="contained"
              fullWidth
              disableElevation
              sx={styles.actionButton}
              size="large"
            >
              View Report
            </Button>
          </CardActions>
        </Card>
      </Link>
    </Grid>
  );
};

ReportCard.propTypes = {
  report: PropTypes.shape(),
  isFavourite: PropTypes.bool,
  addFavourite: PropTypes.func,
  removeFavourite: PropTypes.func,
  userId: PropTypes.string,
  dataDate: PropTypes.string
};

ReportCard.defaultProps = {
  report: {},
  isFavourite: false,
  addFavourite: () => {},
  removeFavourite: () => {},
  userId: "",
  dataDate: ""
};

const mapDispatchToProps = dispatch => ({
  addFavourite: (userId, report) => dispatch(addToFavourites(userId, report)),
  removeFavourite: (userId, report) =>
    dispatch(removeFromFavourites(userId, report))
});

export default connect(null, mapDispatchToProps)(ReportCard);
