import React from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  Typography,
  FormControl,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import { getDateLabel } from "../../utils";

const Review = props => {
  const { searchTerms, story } = props;
  const whatTerms = searchTerms.filter(s => s.table === "what");
  const contextTerms = searchTerms.filter(s => s.table === "context");
  const when = searchTerms.find(s => s.table === "when");
  const dateString = when.name.period
    ? `${when.name.period} ${when.name.date}`
    : getDateLabel(when.name, story);
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Product</Typography>
              </TableCell>
              <TableCell align="right">
                {whatTerms.map((i, k) => (
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontWeight="bold"
                    key={k.toString()}
                  >
                    {i.name}
                  </Typography>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Scope</Typography>
              </TableCell>
              <TableCell align="right">
                {contextTerms.map((i, k) => (
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    fontWeight="bold"
                    key={k.toString()}
                  >
                    {i.name}
                  </Typography>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Time Period</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontWeight="bold"
                >
                  {dateString}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </FormControl>
    </ListItem>
  );
};

Review.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string
};

Review.defaultProps = {
  searchTerms: [],
  story: ""
};

export default Review;
