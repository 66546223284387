import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  parse,
  subDays,
  subWeeks,
  subYears,
  addDays,
  addWeeks,
  isBefore,
  differenceInWeeks
} from "date-fns";
import { Typography, Grid, Collapse } from "@mui/material";
import CustomDatepicker from "./CustomDatepicker";
import CompareOptions from "./CompareOptions";
import CompareDateBox from "./CompareDateBox";
import { enterSelectedTerm, deleteTerm } from "../../actions/search";
import {
  getWeekStart,
  getWeekEnd,
  getDatePeriod,
  getCompareDates
} from "../../utils";

const CustomDate = props => {
  const { onSelect, dataDate, selectedPeriod, periodError, clearErrors } =
    props;
  const [periodOption, setPeriodOption] = useState("yearAgo");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [customDateError, setCustomDateError] = useState(null);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const lastValidDate = parse(dataDate, "dd/MM/yy", new Date());
  const lastValidSunday = subDays(lastValidDate, 6);
  const selectedDates = selectedPeriod
    ? selectedPeriod.name
        .split(",")
        .map((i, k) => (k % 2 === 0 ? getWeekStart(i) : getWeekEnd(i)))
    : null;
  useEffect(() => {
    if (selectedPeriod) {
      setStartDate(selectedDates[2]);
      setEndDate(selectedDates[3]);
      setCustomStartDate(selectedDates[0]);
      setCustomEndDate(selectedDates[1]);
      if (differenceInWeeks(selectedDates[2], selectedDates[0]) !== 52) {
        setPeriodOption("Custom");
      }
    }
  }, []);

  const checkPeriodErrors = (start, end, customStart, customEnd) => {
    const duration = differenceInWeeks(end, start) + 1;
    if (duration > 52) {
      setDateError("The period is longer than 52 weeks");
    } else if (duration < 2) {
      setDateError("The period is less than 2 weeks");
    } else if (duration > 1 && duration < 53) {
      setDateError(null);
    } else {
      setDateError("The selected period doesn't make sense");
    }
    if (periodOption === "Custom") {
      const customDuration = differenceInWeeks(customEnd, customStart) + 1;
      if (duration !== customDuration) {
        setCustomDateError("The selected periods are not of equal length");
      } else {
        setCustomDateError(null);
      }
    }
  };

  return (
    <Fragment>
      <CustomDatepicker
        dateError={selectedPeriod ? dateError : periodError}
        onChangeStart={date => {
          setStartDate(date);
          if (endDate && periodOption !== "Custom") {
            checkPeriodErrors(date, endDate);
            onSelect(date, endDate, periodOption, selectedPeriod);
          } else if (
            endDate &&
            customStartDate &&
            customEndDate &&
            periodOption === "Custom"
          ) {
            checkPeriodErrors(date, endDate, customStartDate, customEndDate);
            if (selectedPeriod) {
              // changing period but not custom period -> remove period from search terms
              onSelect(date, endDate, periodOption, selectedPeriod);
            } else {
              onSelect(
                date,
                endDate,
                periodOption,
                selectedPeriod,
                customStartDate,
                customEndDate
              );
            }
          }
          clearErrors();
        }}
        onChangeEnd={date => {
          setEndDate(date);
          if (periodOption !== "Custom") {
            checkPeriodErrors(startDate, date);
            onSelect(startDate, date, periodOption, selectedPeriod);
          } else if (
            customStartDate &&
            customEndDate &&
            periodOption === "Custom"
          ) {
            checkPeriodErrors(startDate, date, customStartDate, customEndDate);
            if (selectedPeriod) {
              // changing period but not custom period -> remove period from search terms
              onSelect(startDate, date, periodOption, selectedPeriod);
            } else {
              onSelect(
                startDate,
                date,
                periodOption,
                selectedPeriod,
                customStartDate,
                customEndDate
              );
            }
          }
          clearErrors();
        }}
        minStartDate={subYears(lastValidSunday, 3)}
        maxStartDate={subWeeks(lastValidSunday, 1)}
        minEndDate={addWeeks(startDate, 1)}
        maxEndDate={
          isBefore(addWeeks(startDate, 52), lastValidDate)
            ? addWeeks(startDate, 52)
            : lastValidDate
        }
        selectedStart={selectedPeriod ? selectedDates[2] : startDate}
        selectedEnd={selectedPeriod ? selectedDates[3] : endDate}
        disabledStart={false}
        disabledEnd={!startDate}
        openToDate={startDate ? addDays(addWeeks(startDate, 1), 6) : null}
        startDate={startDate}
        endDate={endDate}
        setCalendarOpen={setCalendarOpen}
        hintText="The period cannot be less than 2 weeks or more than 52 weeks"
      />
      <Typography variant="subtitle2" gutterBottom sx={{ mx: 1, mt: 2 }}>
        Please choose a reference period to compare against
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <CompareOptions
            periodOption={periodOption}
            setPeriodOption={setPeriodOption}
            startDate={startDate}
            endDate={endDate}
            checkPeriodErrors={checkPeriodErrors}
            selectedPeriod={selectedPeriod}
            setDateError={setDateError}
            setCustomStartDate={setCustomStartDate}
            setCustomDateError={setCustomDateError}
            setCustomEndDate={setCustomEndDate}
            isCalendarOpen={isCalendarOpen}
            onSelect={onSelect}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Collapse in={periodOption !== "Custom"}>
            <CompareDateBox
              periodOption={periodOption}
              startDate={startDate}
              endDate={endDate}
            />
          </Collapse>
        </Grid>
      </Grid>
      <Collapse in={periodOption === "Custom"}>
        <CustomDatepicker
          dateError={customDateError}
          onChangeStart={date => {
            setCustomStartDate(date);
            const customEnd = addDays(
              addWeeks(date, differenceInWeeks(endDate, startDate)),
              6
            );
            setCustomEndDate(customEnd);
            checkPeriodErrors(startDate, endDate, date, customEnd);
            onSelect(
              startDate,
              endDate,
              periodOption,
              selectedPeriod,
              date,
              customEnd
            );
            clearErrors();
          }}
          onChangeEnd={date => {
            setCustomEndDate(date);
            clearErrors();
          }}
          minStartDate={subYears(lastValidSunday, 3)}
          maxStartDate={subWeeks(
            startDate,
            differenceInWeeks(endDate, startDate) + 1
          )}
          minEndDate={addWeeks(
            customStartDate,
            differenceInWeeks(endDate, startDate)
          )}
          maxEndDate={addWeeks(
            customStartDate,
            differenceInWeeks(endDate, startDate)
          )}
          selectedStart={
            selectedPeriod && customStartDate
              ? selectedDates[0]
              : customStartDate
          }
          selectedEnd={
            selectedPeriod && customEndDate ? selectedDates[1] : customEndDate
          }
          disabledStart={periodOption !== "Custom"}
          disabledEnd
          openToDate={
            customStartDate
              ? addDays(
                  addWeeks(
                    customStartDate,
                    differenceInWeeks(endDate, startDate)
                  ),
                  6
                )
              : null
          }
          startDate={customStartDate}
          endDate={customEndDate}
          setCalendarOpen={setCalendarOpen}
          hintText="The periods must have the same duration and cannot overlap."
        />
      </Collapse>
    </Fragment>
  );
};

CustomDate.propTypes = {
  onSelect: PropTypes.func,
  dataDate: PropTypes.string,
  selectedPeriod: PropTypes.objectOf(PropTypes.string),
  periodError: PropTypes.string,
  clearErrors: PropTypes.func
};

CustomDate.defaultProps = {
  onSelect: () => {},
  dataDate: "",
  selectedPeriod: undefined,
  periodError: "",
  clearErrors: () => {}
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect: (
    startDate,
    endDate,
    periodOption,
    oldValue,
    customStartDate,
    customEndDate
  ) => {
    const { story } = ownProps;
    const diff = differenceInWeeks(endDate, startDate) + 1;
    if (!(diff > 1 && diff < 53)) {
      return;
    }
    if (periodOption === "Custom" && customStartDate && customEndDate) {
      const compareDiff = differenceInWeeks(customEndDate, customStartDate) + 1;
      if (!(compareDiff > 1 && compareDiff < 53)) {
        return;
      }
      if (diff !== compareDiff) {
        return;
      }
    }
    if (oldValue !== undefined) {
      dispatch(deleteTerm(oldValue, story));
    }
    if (periodOption === "Custom" && !customStartDate && !customEndDate) {
      return;
    }
    const [compareStartDate, compareEndDate] = getCompareDates(
      periodOption,
      startDate,
      endDate,
      customStartDate,
      customEndDate
    );
    dispatch(
      enterSelectedTerm(
        {
          name: getDatePeriod(
            startDate,
            endDate,
            compareStartDate,
            compareEndDate
          ),
          subsection: "period",
          table: "when",
          story
        },
        story
      )
    );
  }
});

export default connect(null, mapDispatchToProps)(CustomDate);
