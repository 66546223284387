import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Box,
  Grid
} from "@mui/material";
import { PerformanceIcon } from "../Icons";
import { fetchStaticData } from "../../actions/data";
import { clearStorySearchData, getContext } from "../../actions/search";
import { buildQueryString } from "../../utils";
import Product from "./Product";
import Scope from "./Scope";
import Period from "./Period";
import Review from "./Review";

const NewProductPerformanceReport = props => {
  const {
    isStaticData,
    getData,
    client,
    searchTerms,
    isFilteringData,
    story,
    reset,
    subsections,
    dataDate,
    getProductContext,
    searchEngine,
    isLoadingContext,
    suggestedContext,
    contextSubsections
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [productError, setProductError] = useState("");
  const [contextError, setContextError] = useState("");
  const [periodError, setPeriodError] = useState("");
  useEffect(() => {
    if (!isStaticData) {
      getData(client);
    }
  }, []);

  const product = searchTerms.find(s => s.table === "what");
  const context = searchTerms.find(s => s.table === "context");
  const period = searchTerms.find(s => s.table === "when");

  const showErrors = () => {
    if (!product) {
      setProductError("Please select a product");
    }
    if (!context) {
      setContextError("Please select a context");
    }
    if (!period) {
      setPeriodError("Please select a period");
    }
  };

  const clearErrors = () => {
    setProductError("");
    setContextError("");
    setPeriodError("");
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    reset(story);
  };

  const steps = [
    "Define Product",
    "Define Scope",
    "Define Time Period",
    "Review"
  ];

  return (
    <Card className={!isStaticData || isFilteringData ? "wait" : "overflow"}>
      <CardHeader
        avatar={<PerformanceIcon fontSize="small" color="secondaryDark" />}
        titleTypographyProps={{ variant: "h5" }}
        title="Product Performance Report"
      />
      <Typography variant="body2" sx={{ px: 2 }}>
        Run a performance report to understand how a distributor or brand is
        performing over time.
      </Typography>
      <Stepper alternativeLabel activeStep={activeStep} sx={{ py: 4 }}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {(activeStep === 0 && (
        <Fragment>
          <Product
            subsections={subsections}
            isStaticData={isStaticData}
            product={product}
            story={story}
            productError={productError}
            clearErrors={clearErrors}
            context={context}
          />
          <Box textAlign="right" sx={{ p: 2 }}>
            {product ? (
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  handleNext();
                  getProductContext([product], [], story, client);
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                disableElevation
                onClick={showErrors}
                color="disabled"
              >
                Next
              </Button>
            )}
          </Box>
        </Fragment>
      )) ||
        (activeStep === 1 && (
          <Fragment>
            <Scope
              isLoadingContext={isLoadingContext}
              options={contextSubsections}
              context={context}
              whatTerms={[product]}
              searchEngine={searchEngine}
              story={story}
              clearErrors={clearErrors}
              contextError={contextError}
              suggestedContext={suggestedContext}
              client={client}
            />
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item md={6}>
                <Box textAlign="left" sx={{ p: 2 }}>
                  <Button onClick={handleBack}>Back</Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box textAlign="right" sx={{ p: 2 }}>
                  {context ? (
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={showErrors}
                      color="disabled"
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Fragment>
        )) ||
        (activeStep === 2 && (
          <Fragment>
            <Period
              options={subsections.filter(s => s.table === "when")}
              period={period}
              story={story}
              periodError={periodError}
              clearErrors={clearErrors}
              dataDate={dataDate}
            />
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item md={6}>
                <Box textAlign="left" sx={{ p: 2 }}>
                  <Button onClick={handleBack}>Back</Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box textAlign="right" sx={{ p: 2 }}>
                  {period ? (
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={showErrors}
                      color="disabled"
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Fragment>
        )) ||
        (activeStep === 3 && (
          <Fragment>
            <Review searchTerms={searchTerms} />
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item md={6}>
                <Box textAlign="left" sx={{ p: 2 }}>
                  <Button onClick={handleBack}>Back</Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box textAlign="right" sx={{ p: 2 }}>
                  <Link
                    to={`/reports?story=${story.toUpperCase()}&${buildQueryString(
                      searchTerms
                    )}`}
                    onClick={handleReset}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                    >
                      Generate Report
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Fragment>
        ))}
    </Card>
  );
};

NewProductPerformanceReport.propTypes = {
  searchTerms: PropTypes.arrayOf(PropTypes.shape()),
  getData: PropTypes.func,
  isStaticData: PropTypes.bool,
  isFilteringData: PropTypes.bool,
  story: PropTypes.string,
  reset: PropTypes.func,
  client: PropTypes.string,
  subsections: PropTypes.arrayOf(PropTypes.shape()),
  dataDate: PropTypes.string,
  getProductContext: PropTypes.func,
  searchEngine: PropTypes.shape(),
  isLoadingContext: PropTypes.bool,
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  contextSubsections: PropTypes.arrayOf(PropTypes.shape())
};

NewProductPerformanceReport.defaultProps = {
  searchTerms: [],
  getData: () => {},
  isStaticData: false,
  isFilteringData: false,
  story: "",
  reset: () => {},
  client: "",
  subsections: [],
  dataDate: "",
  getProductContext: () => {},
  searchEngine: {},
  isLoadingContext: false,
  suggestedContext: [],
  contextSubsections: []
};

const mapStateToProps = (state, ownProps) => {
  const { story, dataSet } = ownProps;
  const {
    search: {
      searchTerms = { story: [] },
      isLoadingContext = false,
      context = { story: [] },
      contextSearchEngine = { story: {} },
      suggestedContext = { story: [] }
    },
    data: {
      subsections = { dataSet: [] },
      isStaticData = false,
      date = { dataSet: "" }
    }
  } = state;
  return {
    searchTerms: searchTerms[story],
    subsections: subsections[dataSet],
    isStaticData,
    isLoadingContext,
    contextSubsections: context[story],
    searchEngine: contextSearchEngine[story],
    suggestedContext: suggestedContext[story],
    client: state.user.user.client,
    dataDate: date[dataSet]
  };
};

const mapDispatchToProps = dispatch => ({
  getData: client => {
    dispatch(fetchStaticData(client));
  },
  reset: story => {
    dispatch(clearStorySearchData(story));
  },
  getProductContext: (product, context, story, client) => {
    dispatch(getContext(product, context, "product", story, client)); // TODO: isInitial?
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewProductPerformanceReport);
