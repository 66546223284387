import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  ListItem,
  Collapse,
  Button,
  Box,
  FormHelperText,
  Chip,
  FormControl,
  FormLabel,
  Typography
} from "@mui/material";
import { AdvancedSearch, SearchBar } from "../SharedComponents";
import { enterSelectedTerm, deleteTerm, getContext } from "../../actions";
import { InfoIcon } from "../Icons";

const Scope = props => {
  const {
    options,
    context,
    whatTerms,
    story,
    isLoadingContext,
    onChange,
    searchEngine,
    clearErrors,
    contextError,
    suggestedContext,
    enterSuggestedContext,
    client
  } = props;
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            Select Product Scope
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" sx={{ mx: 1 }}>
          Add the scope to use for this report
        </Typography>
        <Box sx={{ px: 1, my: -1 }}>
          <SearchBar
            searchOptions={searchEngine._docs || []}
            value={context}
            onChange={(e, val, reason) => {
              if (_.isEqual(reason, "selectOption")) {
                onChange(val, reason, story, context, whatTerms, client);
              } else if (_.isEqual(reason, "removeOption")) {
                const toDelete = _.difference(context, val)[0];
                onChange(toDelete, reason, story, context, whatTerms, client);
              } else if (_.isEqual(reason, "clear")) {
                onChange(context, reason, story, context, whatTerms, client);
              }
              clearErrors();
            }}
            searchEngine={searchEngine}
            disabled={isLoadingContext}
          />
        </Box>
        <ListItem disablePadding>
          <InfoIcon color="primary" sx={{ m: 1 }} />
          <Typography variant="subtitle2" color="primary">
            This filters the report so that you can see performance within a
            given context
          </Typography>
        </ListItem>
        <FormHelperText error>{contextError}</FormHelperText>
        {!context && (
          <Fragment>
            <Typography variant="subtitle2" gutterBottom sx={{ m: 1 }}>
              Suggested Context
            </Typography>
            <Box sx={{ mx: 0.5 }}>
              {suggestedContext.map((i, k) => (
                <Chip
                  key={k.toString()}
                  sx={{ mx: 0.5 }}
                  label={i.name.value ? i.name.value : i.name}
                  onClick={() => {
                    enterSuggestedContext(i, story, context, whatTerms, client);
                  }}
                  disabled={isLoadingContext}
                />
              ))}
            </Box>
          </Fragment>
        )}
        <Collapse in={!isAdvancedSearch} sx={{ mt: 3 }}>
          <Fragment>
            <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
              Can&apos;t find what you&apos;re looking for?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              sx={{ m: 1 }}
              onClick={() => setIsAdvancedSearch(true)}
            >
              Advanced Search
            </Button>
          </Fragment>
        </Collapse>
        <Collapse in={isAdvancedSearch}>
          <AdvancedSearch
            searchTerms={context ? [context] : []}
            options={options}
            story={story}
            client={client}
            isFilteringData={isLoadingContext}
            isContext
            whatTerms={whatTerms} // required for updating context
            clearErrors={clearErrors}
            disableMultiple
          />
          <Box textAlign="center">
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsAdvancedSearch(false);
              }}
            >
              Close advanced search
            </Button>
          </Box>
        </Collapse>
      </FormControl>
    </ListItem>
  );
};

const mapDispatchToProps = dispatch => ({
  onChange: (selected, e, story, contextTerms, whatTerms, client) => {
    if (_.isEqual(e, "selectOption")) {
      const term = selected.name.value
        ? { ...selected, name: selected.name.value, story }
        : { ...selected, story };
      dispatch(enterSelectedTerm(term, story));
    } else if (_.isEqual(e, "removeOption")) {
      dispatch(deleteTerm(selected, story));
      const context = contextTerms.filter(t => !_.isEqual(t, selected));
      dispatch(getContext(whatTerms, context, "product", story, client));
    } else if (_.isEqual(e, "clear")) {
      dispatch(deleteTerm(selected, story));
      dispatch(getContext(whatTerms, [], "product", story, client));
    }
  },
  enterSuggestedContext: (selected, story, context) => {
    if (context) {
      dispatch(deleteTerm(context, story));
    }
    const term = {
      name: selected.name.value ? selected.name.value : selected.name,
      subsection: selected.subsection,
      table: "context",
      story
    };
    dispatch(enterSelectedTerm(term, story));
  }
});

Scope.propTypes = {
  context: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape()),
  whatTerms: PropTypes.arrayOf(PropTypes.shape()),
  story: PropTypes.string,
  isLoadingContext: PropTypes.bool,
  onChange: PropTypes.func,
  searchEngine: PropTypes.shape(),
  clearErrors: PropTypes.func,
  contextError: PropTypes.string,
  suggestedContext: PropTypes.arrayOf(PropTypes.shape()),
  enterSuggestedContext: PropTypes.func,
  client: PropTypes.string
};

Scope.defaultProps = {
  context: undefined,
  options: [],
  whatTerms: [],
  story: "",
  isLoadingContext: false,
  onChange: () => {},
  searchEngine: {},
  clearErrors: () => {},
  contextError: "",
  suggestedContext: [],
  enterSuggestedContext: () => {},
  client: ""
};

export default connect(null, mapDispatchToProps)(Scope);
