const colours = {
  primary: "#009dbc",
  secondary: "#000054",
  lightgrey: "#dddddd",
  darkgrey: "#bbbbbb",
  accent1: "#d93b24",
  accent2: "#e39717",
  light: "#f9f9f9",
  primaryArray: [
    "#ccebf2",
    "#80cedd",
    "#009cbb",
    "#00697d",
    "#00343f",
    "#012127"
  ],
  secondaryArray: [
    "#ccccdd",
    "#aaaac6",
    "#55558d",
    "#2a2a70",
    "#000054",
    "#00001c"
  ],
  sequential1: [
    "#009dbc",
    "#269ca1",
    "#4c9b85",
    "#729a6a",
    "#97994e",
    "#bd9833",
    "#e39717"
  ],
  sequential2: [
    "#009dbc",
    "#248da3",
    "#487c89",
    "#6d6c70",
    "#915c57",
    "#b54b3d",
    "#d93b24"
  ],
  sequential3: [
    "#000054",
    "#26194a",
    "#26194a",
    "#724c36",
    "#97652b",
    "#bd7e21",
    "#e39717"
  ]
};

const theme = {
  ...colours,
  "Value Sales": colours.primary,
  "Volume Sales": colours.accent1,
  "Basket Incidence": colours.accent2
};

export default theme;
