import {
  CREATE_USER_URL,
  SIGN_IN_URL,
  FORGOT_PASSWORD_URL,
  FORGOT_PASSWORD_RESET_URL,
  CHANGE_PASSWORD_URL,
  UNBLOCK_USER_URL,
  DISABLE_USER_URL,
  ENABLE_USER_URL,
  RESET_PASSWORD_URL,
  API_KEY,
  ADD_CONTACT_URL,
  REMOVE_CONTACT_URL,
  CHECK_SUBSCRIPTION_URL,
  SUBSCRIBE_URL,
  UNSUBSCRIBE_URL,
  GET_CLIENTS_URL,
  GET_DEPARTMENTS_URL,
  ADD_DEPARTMENT_URL
} from "../constants";
import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_ERROR,
  DISABLE_USER_REQUEST,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_ERROR,
  ENABLE_USER_REQUEST,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CLEAR_USER_MESSAGES,
  LOGOUT,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_ERROR,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  REMOVE_CONTACT_REQUEST,
  REMOVE_CONTACT_SUCCESS,
  REMOVE_CONTACT_ERROR,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_ERROR,
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR
} from "../constants/actionConstants";
import { getClientURL } from "../config";
import logger from "../logger";

/**
 * Ensure all user input emails are lowercase
 */
const lowerCaseEmail = email => email.toLowerCase();

/**
 * Validate email to ensure it is at least 8 characters and contains all of
 * lowercase letters, uppercase letters, numbers and special characters as defined by
 * https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
 */
const checkPasswordErrors = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return "Passwords do not match";
  }
  if (password.length < 8) {
    return "Password must be at least 8 characters";
  }
  if (password.length >= 64) {
    return "Password must be less than 64 characters";
  }
  if (!password.match(/(\S*[a-z]\S*)/)) {
    return "Password must contain a lowercase character";
  }
  if (!password.match(/(\S*[A-Z]\S*)/)) {
    return "Password must contain an uppercase character";
  }
  if (!password.match(/(\S*\d\S*)/)) {
    return "Password must contain a number";
  }
  if (!password.match(/(\S*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]\S*)/)) {
    return "Password must contain a special character";
  }
  return false;
};

function addContactRequest() {
  return {
    type: ADD_CONTACT_REQUEST
  };
}

function addContactSuccess() {
  return {
    type: ADD_CONTACT_SUCCESS
  };
}

function addContactError(error) {
  return {
    type: ADD_CONTACT_ERROR,
    error
  };
}

function addContact(dispatch, email) {
  dispatch(addContactRequest());
  return fetch(ADD_CONTACT_URL, {
    method: "post",
    body: JSON.stringify({ email }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(addContactSuccess());
        } else {
          dispatch(addContactError(res));
        }
      },
      err => {
        dispatch(addContactError(err.message));
      }
    );
}

function removeContactRequest() {
  return {
    type: REMOVE_CONTACT_REQUEST
  };
}

function removeContactSuccess() {
  return {
    type: REMOVE_CONTACT_SUCCESS
  };
}

function removeContactError(error) {
  return {
    type: REMOVE_CONTACT_ERROR,
    error
  };
}

function removeContact(dispatch, email) {
  dispatch(removeContactRequest());
  return fetch(REMOVE_CONTACT_URL, {
    method: "post",
    body: JSON.stringify({ email }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(removeContactSuccess());
        } else {
          dispatch(removeContactError(res));
        }
      },
      err => {
        dispatch(removeContactError(err.message));
      }
    );
}

function createUserRequest() {
  return {
    type: CREATE_USER_REQUEST
  };
}

function createUserSuccess() {
  return {
    type: CREATE_USER_SUCCESS
  };
}

function createUserError(error) {
  return {
    type: CREATE_USER_ERROR,
    error
  };
}

export const createUser =
  (name, inputEmail, password, confirmPassword, department, client) =>
  dispatch => {
    const email = lowerCaseEmail(inputEmail);
    dispatch(createUserRequest());
    if (name.length === 0) {
      return dispatch(createUserError("Name is not valid"));
    }
    if (email.length === 0) {
      return dispatch(createUserError("Email is not valid"));
    }
    if (!email.match(/\S+@\S+\.\S+/)) {
      return dispatch(createUserError("Email is not valid"));
    }
    const passwordErrors = checkPasswordErrors(password, confirmPassword);
    if (passwordErrors) {
      return dispatch(createUserError(passwordErrors));
    }
    if (!email.includes("@interrodata.com") && department.length === 0) {
      return dispatch(createUserError("Department not valid"));
    }
    const body = email.includes("@interrodata.com")
      ? { name, email, password }
      : {
          name,
          email,
          password,
          department,
          client,
          product: "ida"
        };
    return fetch(CREATE_USER_URL, {
      method: "post",
      body: JSON.stringify(body),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            addContact(dispatch, email);
            dispatch(createUserSuccess());
          } else {
            dispatch(createUserError(res));
          }
        },
        err => {
          dispatch(createUserError(err.message));
        }
      );
  };

function signInRequest(user) {
  return {
    type: SIGN_IN_REQUEST,
    user
  };
}

function signInSuccess(user) {
  return {
    type: SIGN_IN_SUCCESS,
    user
  };
}

function signInError(error) {
  return {
    type: SIGN_IN_ERROR,
    error
  };
}

export const signIn = (inputEmail, password, pseudoClient) => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  const user = { email };
  dispatch(signInRequest(user));
  if (email.length === 0 || password.length === 0) {
    return dispatch(signInError("Please enter a valid email and password"));
  }
  if (email.includes("@interrodata.com") && pseudoClient.length === 0) {
    return dispatch(signInError("Please select a valid client"));
  }
  return fetch(SIGN_IN_URL, {
    method: "post",
    body: JSON.stringify({ email, password }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res.status === "ACCEPTED") {
          const { admin, id, client, product } = res;
          if (admin) {
            // i.e. interrodata user
            dispatch(signInSuccess({ admin, id, client: pseudoClient }));
            logger.info({
              date: new Date().toISOString(),
              action: "SIGN_IN",
              user_id: id,
              client: "interrodata", // this is also what comes from user api
              product: "ida"
            });
          } else if (product !== "ida") {
            // not admin
            dispatch(signInError("Permission denied"));
          } else if (
            process.env.NODE_ENV === "production" &&
            process.env.REACT_APP_DEV_ENV === "prod" &&
            window.location.hostname !== getClientURL(client)
          ) {
            // not admin
            dispatch(signInError("Permission denied"));
          } else {
            // not admin
            dispatch(signInSuccess({ admin, id, client }));
            logger.info({
              date: new Date().toISOString(),
              action: "SIGN_IN",
              user_id: id,
              client,
              product: "ida"
            });
          }
        } else if (res.status === "DENIED") {
          dispatch(signInError("Access denied"));
        } else {
          dispatch(signInError(res));
        }
      },
      err => {
        if (err.message === "Failed to fetch") {
          dispatch(
            signInError(
              "Failed to fetch. Between 12AM and 5AM (GMT) and over the weekend the database has some down time."
            )
          );
        } else {
          dispatch(signInError(err.message));
        }
      }
    );
};

function forgotPasswordRequest() {
  return {
    type: FORGOT_PASSWORD_REQUEST
  };
}

function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS
  };
}

function forgotPasswordError(error) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    error
  };
}

export const forgotPassword = (inputEmail, onComplete) => dispatch => {
  dispatch(forgotPasswordRequest());
  const email = lowerCaseEmail(inputEmail);
  if (email.length === 0) {
    return dispatch(forgotPasswordError("Please enter a valid email"));
  }
  return fetch(FORGOT_PASSWORD_URL, {
    method: "post",
    body: JSON.stringify({ email }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          onComplete();
          dispatch(forgotPasswordSuccess());
        } else {
          // for security reason, always display success message
          onComplete();
          dispatch(forgotPasswordError(res));
        }
      },
      err => {
        dispatch(forgotPasswordError(err.message));
      }
    );
};

export const forgotPasswordReset =
  (inputEmail, token, password, confirmPassword, onComplete) => dispatch => {
    dispatch(forgotPasswordRequest());
    const email = lowerCaseEmail(inputEmail);
    if (email.length === 0) {
      return dispatch(forgotPasswordError("Please enter a valid email"));
    }
    if (token.length === 0) {
      return dispatch(forgotPasswordError("Please enter a valid token"));
    }
    const passwordErrors = checkPasswordErrors(password, confirmPassword);
    if (passwordErrors) {
      return dispatch(forgotPasswordError(passwordErrors));
    }
    return fetch(FORGOT_PASSWORD_RESET_URL, {
      method: "post",
      body: JSON.stringify({ email, token, password }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            onComplete(true);
            dispatch(forgotPasswordSuccess());
          } else {
            onComplete(false);
            dispatch(forgotPasswordError(res));
          }
        },
        err => {
          dispatch(forgotPasswordError(err.message));
        }
      );
  };

function changePasswordRequest() {
  return {
    type: CHANGE_PASSWORD_REQUEST
  };
}

function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS
  };
}

function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error
  };
}

export const changePassword =
  (userId, password, newPassword, confirmNewPassword) => dispatch => {
    dispatch(changePasswordRequest());
    const passwordErrors = checkPasswordErrors(newPassword, confirmNewPassword);
    if (passwordErrors) {
      return dispatch(changePasswordError(passwordErrors));
    }
    return fetch(CHANGE_PASSWORD_URL, {
      method: "post",
      body: JSON.stringify({
        user_id: userId,
        password,
        new_password: newPassword
      }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            dispatch(changePasswordSuccess());
          } else {
            dispatch(changePasswordError(res));
          }
        },
        err => {
          dispatch(changePasswordError(err.message));
        }
      );
  };

function unblockUserRequest() {
  return {
    type: UNBLOCK_USER_REQUEST
  };
}

function unblockUserSuccess() {
  return {
    type: UNBLOCK_USER_SUCCESS
  };
}

function unblockUserError(error) {
  return {
    type: UNBLOCK_USER_ERROR,
    error
  };
}

export const unblockUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(unblockUserRequest());
  if (email.length === 0) {
    return dispatch(unblockUserError("Email is not valid"));
  }
  return fetch(UNBLOCK_USER_URL, {
    method: "post",
    body: JSON.stringify({ email }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(unblockUserSuccess());
        } else {
          dispatch(unblockUserError(res));
        }
      },
      err => {
        dispatch(unblockUserError(err.message));
      }
    );
};

function disableUserRequest() {
  return {
    type: DISABLE_USER_REQUEST
  };
}

function disableUserSuccess() {
  return {
    type: DISABLE_USER_SUCCESS
  };
}

function disableUserError(error) {
  return {
    type: DISABLE_USER_ERROR,
    error
  };
}

export const disableUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(disableUserRequest());
  if (email.length === 0) {
    return dispatch(disableUserError("Email is not valid"));
  }
  return fetch(DISABLE_USER_URL, {
    method: "post",
    body: JSON.stringify({ email }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          removeContact(dispatch, email);
          dispatch(disableUserSuccess());
        } else {
          dispatch(disableUserError(res));
        }
      },
      err => {
        dispatch(disableUserError(err.message));
      }
    );
};

function enableUserRequest() {
  return {
    type: ENABLE_USER_REQUEST
  };
}

function enableUserSuccess() {
  return {
    type: ENABLE_USER_SUCCESS
  };
}

function enableUserError(error) {
  return {
    type: ENABLE_USER_ERROR,
    error
  };
}

export const enableUser = inputEmail => dispatch => {
  const email = lowerCaseEmail(inputEmail);
  dispatch(enableUserRequest());
  if (email.length === 0) {
    return dispatch(enableUserError("Email is not valid"));
  }
  return fetch(ENABLE_USER_URL, {
    method: "post",
    body: JSON.stringify({ email }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          addContact(dispatch, email);
          dispatch(enableUserSuccess());
        } else {
          dispatch(enableUserError(res));
        }
      },
      err => {
        dispatch(enableUserError(err.message));
      }
    );
};

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_REQUEST
  };
}

function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS
  };
}

function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error
  };
}

export const resetPassword =
  (inputEmail, password, confirmPassword) => dispatch => {
    const email = lowerCaseEmail(inputEmail);
    dispatch(resetPasswordRequest());
    if (email.length === 0 || password.length === 0) {
      return dispatch(
        resetPasswordError("Please enter a valid email and password")
      );
    }
    const passwordErrors = checkPasswordErrors(password, confirmPassword);
    if (passwordErrors) {
      return dispatch(resetPasswordError(passwordErrors));
    }
    return fetch(RESET_PASSWORD_URL, {
      method: "post",
      body: JSON.stringify({ email, password }),
      headers: { "X-API-KEY": API_KEY }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(
        res => {
          if (res === "SUCCESS") {
            dispatch(resetPasswordSuccess());
          } else {
            dispatch(resetPasswordError(res));
          }
        },
        err => {
          dispatch(resetPasswordError(err.message));
        }
      );
  };

export function clearSettingsMessages() {
  return {
    type: CLEAR_USER_MESSAGES
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

function getDepartmentsRequest() {
  return {
    type: GET_DEPARTMENTS_REQUEST
  };
}

function getDepartmentsSuccess(departments) {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    departments
  };
}

function getDepartmentsError(error) {
  return {
    type: GET_DEPARTMENTS_ERROR,
    error
  };
}

export const getDepartments = client => dispatch => {
  dispatch(getDepartmentsRequest());
  return fetch(GET_DEPARTMENTS_URL, {
    method: "post",
    body: JSON.stringify({ client }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        const { Items } = res;
        const departments = Items.map(d => d.department.S).sort();
        dispatch(getDepartmentsSuccess(departments));
      },
      err => {
        dispatch(getDepartmentsError(err.message));
      }
    );
};

function addDepartmentRequest() {
  return {
    type: ADD_DEPARTMENT_REQUEST
  };
}

function addDepartmentSuccess(department) {
  return {
    type: ADD_DEPARTMENT_SUCCESS,
    department
  };
}

function addDepartmentError(error) {
  return {
    type: ADD_DEPARTMENT_ERROR,
    error
  };
}

export const addDepartment = (department, client) => dispatch => {
  dispatch(addDepartmentRequest());
  return fetch(ADD_DEPARTMENT_URL, {
    method: "post",
    body: JSON.stringify({ department, client }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(addDepartmentSuccess(department));
        } else {
          dispatch(addDepartmentError(res));
        }
      },
      err => {
        dispatch(addDepartmentError(err.message));
      }
    );
};

function getClientsRequest() {
  return {
    type: GET_CLIENTS_REQUEST
  };
}

function getClientsSuccess(clients) {
  return {
    type: GET_CLIENTS_SUCCESS,
    clients
  };
}

function getClientsError(error) {
  return {
    type: GET_CLIENTS_ERROR,
    error
  };
}

export const getClients = () => dispatch => {
  dispatch(getClientsRequest());
  return fetch(GET_CLIENTS_URL, {
    method: "post",
    body: JSON.stringify({ product: "ida" }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        const { Items } = res;
        const clients = Items.map(i => i.client.S).sort();
        dispatch(getClientsSuccess(clients));
      },
      err => {
        dispatch(getClientsError(err.message));
      }
    );
};

function getSubscriptionRequest() {
  return {
    type: GET_SUBSCRIPTION_REQUEST
  };
}

function getSubscriptionSuccess(subscriptions) {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
    subscriptions
  };
}

function getSubscriptionError(error) {
  return {
    type: GET_SUBSCRIPTION_ERROR,
    error
  };
}

export const getSubscription = userId => dispatch => {
  dispatch(getSubscriptionRequest());
  return fetch(CHECK_SUBSCRIPTION_URL, {
    method: "post",
    body: JSON.stringify({ user_id: userId }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        const subscriptions = res.reduce(
          (o, i) =>
            Object.assign(o, {
              [i.TopicName]: i.SubscriptionStatus
            }),
          {}
        );
        dispatch(getSubscriptionSuccess(subscriptions));
      },
      err => {
        dispatch(getSubscriptionError(err.message));
      }
    );
};

function subscribeRequest() {
  return {
    type: SUBSCRIBE_REQUEST
  };
}

function subscribeSuccess(topic) {
  return {
    type: SUBSCRIBE_SUCCESS,
    topic
  };
}

function subscribeError(error) {
  return {
    type: SUBSCRIBE_ERROR,
    error
  };
}

export const subscribe = (userId, topic) => async dispatch => {
  dispatch(subscribeRequest());
  return fetch(SUBSCRIBE_URL, {
    method: "post",
    body: JSON.stringify({ user_id: userId, topic }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(subscribeSuccess(topic));
        } else {
          dispatch(subscribeError(res));
        }
      },
      err => {
        dispatch(subscribeError(err.message));
      }
    );
};

function unsubscribeRequest() {
  return {
    type: UNSUBSCRIBE_REQUEST
  };
}

function unsubscribeSuccess(topic) {
  return {
    type: UNSUBSCRIBE_SUCCESS,
    topic
  };
}

function unsubscribeError(error) {
  return {
    type: UNSUBSCRIBE_ERROR,
    error
  };
}

export const unsubscribe = (userId, topic) => async dispatch => {
  dispatch(unsubscribeRequest());
  return fetch(UNSUBSCRIBE_URL, {
    method: "post",
    body: JSON.stringify({ user_id: userId, topic }),
    headers: { "X-API-KEY": API_KEY }
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(
      res => {
        if (res === "SUCCESS") {
          dispatch(unsubscribeSuccess(topic));
        } else {
          dispatch(unsubscribeError(res));
        }
      },
      err => {
        dispatch(unsubscribeError(err.message));
      }
    );
};
