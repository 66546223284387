import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Card, CardHeader, Avatar, Collapse } from "@mui/material";
import { connect } from "react-redux";
import { clearSettingsMessages } from "../../actions/user";
import {
  SettingsIcon,
  ChangePasswordIcon,
  CreateAccountIcon,
  UnblockUserIcon,
  DisableUserIcon,
  EnableUserIcon,
  ResetPasswordIcon
} from "../Icons";
import ChangePassword from "./ChangePassword";
import CreateAccount from "./CreateAccount";
import UnblockUser from "./UnblockUser";
import DisableUser from "./DisableUser";
import EnableUser from "./EnableUser";
import ResetPassword from "./ResetPassword";

const styles = {
  option: {
    height: 120,
    color: "secondary.dark",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
      color: "secondary.main"
    }
  },
  avatar: {
    borderRadius: "50%",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.3)",
    bgcolor: "white",
    ml: 1
  }
};

const Settings = props => {
  const {
    userId,
    isAdmin,
    client,
    isLoading,
    changePasswordError,
    changePasswordMessage,
    createUserError,
    createUserMessage,
    unblockUserError,
    unblockUserMessage,
    disableUserError,
    disableUserMessage,
    enableUserError,
    enableUserMessage,
    resetPasswordError,
    resetPasswordMessage,
    subscriptionError,
    subscriptionMessage,
    clearMessages,
    departments
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState(null);

  const onClose = () => {
    setIsOpen(false);
    clearMessages();
  };

  const showForm = () => {
    switch (option) {
      case "change-password":
        return (
          <ChangePassword
            userId={userId}
            onClose={onClose}
            error={changePasswordError}
            message={changePasswordMessage}
            isLoading={isLoading}
          />
        );
      case "create-account":
        return isAdmin ? (
          <CreateAccount
            client={client}
            onClose={onClose}
            error={createUserError}
            message={createUserMessage}
            isLoading={isLoading}
            departments={departments}
            subscriptionError={subscriptionError}
            subscriptionMessage={subscriptionMessage}
          />
        ) : null;
      case "unblock-users":
        return isAdmin ? (
          <UnblockUser
            onClose={onClose}
            error={unblockUserError}
            message={unblockUserMessage}
            isLoading={isLoading}
          />
        ) : null;
      case "disable-user":
        return isAdmin ? (
          <DisableUser
            onClose={onClose}
            error={disableUserError}
            message={disableUserMessage}
            isLoading={isLoading}
          />
        ) : null;
      case "enable-user":
        return isAdmin ? (
          <EnableUser
            onClose={onClose}
            error={enableUserError}
            message={enableUserMessage}
            isLoading={isLoading}
          />
        ) : null;
      case "reset-password":
        return isAdmin ? (
          <ResetPassword
            onClose={onClose}
            error={resetPasswordError}
            message={resetPasswordMessage}
            isLoading={isLoading}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <Card sx={{ p: 2.5, pb: 10, width: 1 }}>
      <CardHeader
        titleTypographyProps={{ variant: "h2" }}
        avatar={<SettingsIcon color="secondary" />}
        title="Settings"
      />
      <Collapse in={isOpen}>{showForm()}</Collapse>
      <Collapse in={!isOpen}>
        <Grid container spacing={3} sx={{ p: 2 }}>
          <Grid item md={4} xs={6}>
            <Card
              sx={styles.option}
              onClick={() => {
                setIsOpen(true);
                setOption("change-password");
              }}
            >
              <CardHeader
                titleTypographyProps={{
                  variant: "body1",
                  fontWeight: "bold"
                }}
                avatar={
                  <Avatar sx={styles.avatar}>
                    <ChangePasswordIcon
                      color="secondaryDark"
                      fontSize="small"
                    />
                  </Avatar>
                }
                title="Change password"
              />
            </Card>
          </Grid>
          {isAdmin && (
            <Fragment>
              <Grid item md={4} xs={6}>
                <Card
                  sx={styles.option}
                  onClick={() => {
                    setIsOpen(true);
                    setOption("create-account");
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{
                      variant: "body1",
                      fontWeight: "bold"
                    }}
                    avatar={
                      <Avatar sx={styles.avatar}>
                        <CreateAccountIcon
                          color="secondaryDark"
                          fontSize="small"
                        />
                      </Avatar>
                    }
                    title="Create an account"
                  />
                </Card>
              </Grid>
              <Grid item md={4} xs={6}>
                <Card
                  sx={styles.option}
                  onClick={() => {
                    setIsOpen(true);
                    setOption("unblock-users");
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{
                      variant: "body1",
                      fontWeight: "bold"
                    }}
                    avatar={
                      <Avatar sx={styles.avatar}>
                        <UnblockUserIcon
                          color="secondaryDark"
                          fontSize="small"
                        />
                      </Avatar>
                    }
                    title="Unblock users"
                  />
                </Card>
              </Grid>
              <Grid item md={4} xs={6}>
                <Card
                  sx={styles.option}
                  onClick={() => {
                    setIsOpen(true);
                    setOption("disable-user");
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{
                      variant: "body1",
                      fontWeight: "bold"
                    }}
                    avatar={
                      <Avatar sx={styles.avatar}>
                        <DisableUserIcon
                          color="secondaryDark"
                          fontSize="small"
                        />
                      </Avatar>
                    }
                    title="Disable user"
                  />
                </Card>
              </Grid>
              <Grid item md={4} xs={6}>
                <Card
                  sx={styles.option}
                  onClick={() => {
                    setIsOpen(true);
                    setOption("enable-user");
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{
                      variant: "body1",
                      fontWeight: "bold"
                    }}
                    avatar={
                      <Avatar sx={styles.avatar}>
                        <EnableUserIcon
                          color="secondaryDark"
                          fontSize="small"
                        />
                      </Avatar>
                    }
                    title="Enable user"
                  />
                </Card>
              </Grid>
              <Grid item md={4} xs={6}>
                <Card
                  sx={styles.option}
                  onClick={() => {
                    setIsOpen(true);
                    setOption("reset-password");
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{
                      variant: "body1",
                      fontWeight: "bold"
                    }}
                    avatar={
                      <Avatar sx={styles.avatar}>
                        <ResetPasswordIcon
                          color="secondaryDark"
                          fontSize="small"
                        />
                      </Avatar>
                    }
                    title="Reset password"
                  />
                </Card>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Collapse>
    </Card>
  );
};

Settings.propTypes = {
  userId: PropTypes.string,
  isAdmin: PropTypes.bool,
  client: PropTypes.string,
  isLoading: PropTypes.bool,
  changePasswordError: PropTypes.string,
  changePasswordMessage: PropTypes.string,
  createUserError: PropTypes.string,
  createUserMessage: PropTypes.string,
  unblockUserError: PropTypes.string,
  unblockUserMessage: PropTypes.string,
  disableUserError: PropTypes.string,
  disableUserMessage: PropTypes.string,
  enableUserError: PropTypes.string,
  enableUserMessage: PropTypes.string,
  resetPasswordError: PropTypes.string,
  resetPasswordMessage: PropTypes.string,
  subscriptionError: PropTypes.string,
  subscriptionMessage: PropTypes.string,
  clearMessages: PropTypes.func,
  departments: PropTypes.arrayOf(PropTypes.string)
};

Settings.defaultProps = {
  userId: "",
  isAdmin: false,
  client: "",
  isLoading: false,
  changePasswordError: "",
  changePasswordMessage: "",
  createUserError: "",
  createUserMessage: "",
  unblockUserError: "",
  unblockUserMessage: "",
  disableUserError: "",
  disableUserMessage: "",
  enableUserError: "",
  enableUserMessage: "",
  resetPasswordError: "",
  resetPasswordMessage: "",
  subscriptionError: "",
  subscriptionMessage: "",
  clearMessages: () => {},
  departments: []
};

const mapStateToProps = state => ({
  userId: state.user.user.id,
  isAdmin: state.user.user.admin,
  client: state.user.user.client,
  isLoading: state.user.isLoading,
  changePasswordError: state.user.changePasswordError,
  changePasswordMessage: state.user.changePasswordMessage,
  createUserError: state.user.createUserError,
  createUserMessage: state.user.createUserMessage,
  unblockUserError: state.user.unblockUserError,
  unblockUserMessage: state.user.unblockUserMessage,
  disableUserError: state.user.disableUserError,
  disableUserMessage: state.user.disableUserMessage,
  enableUserError: state.user.enableUserError,
  enableUserMessage: state.user.enableUserMessage,
  resetPasswordError: state.user.resetPasswordError,
  resetPasswordMessage: state.user.resetPasswordMessage,
  subscriptionError: state.user.subscriptionError,
  subscriptionMessage: state.user.subscriptionMessage,
  departments: state.user.departments
});

const mapDispatchToProps = dispatch => ({
  clearMessages: () => {
    dispatch(clearSettingsMessages());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
