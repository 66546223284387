import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { PieChart } from "./Charts";

const Trends = props => {
  const { data, nl, theme } = props;
  const getPieData = (inputData, colours) => {
    const mappedData = [];
    _.forEach(inputData, (val, key) => {
      mappedData.push({ name: key, value: val });
    });
    return mappedData
      .sort((a, b) => (a.value < b.value ? 1 : -1))
      .map((i, k) => ({
        ...i,
        color: colours[k % colours.length]
      }));
  };
  return (
    <Card raised sx={{ height: 1, width: 1 }}>
      <CardContent>
        <Grid container justifyContent="space-evenly" spacing={2}>
          <Grid item md={12}>
            <Box height={150}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    data={getPieData(data[0].t2, theme.sequential1)}
                    xVar="name"
                    yVar="value"
                    width={width}
                    height={height}
                    legend
                  />
                )}
              </ParentSize>
            </Box>
            <Card
              variant="outlined"
              sx={{ p: 1, mt: 3, width: 1, bgcolor: theme.secondary }}
            >
              <Typography variant="dashboardBody">{nl[0]}</Typography>
            </Card>
          </Grid>
          <Grid item md={12}>
            <Box height={150}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    data={getPieData(data[1].t2, theme.sequential2)}
                    xVar="name"
                    yVar="value"
                    width={width}
                    height={height}
                    legend
                  />
                )}
              </ParentSize>
            </Box>
            <Card
              variant="outlined"
              sx={{ p: 1, mt: 3, width: 1, bgcolor: theme.secondary }}
            >
              <Typography variant="dashboardBody">{nl[1]}</Typography>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Trends.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  nl: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.shape()
};

Trends.defaultProps = {
  data: [],
  nl: [],
  theme: {}
};

export default Trends;
