import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { PieChart, WordWeb } from "./Charts";

const BusinessInsights = props => {
  const { data, nl, theme } = props;
  const getPieData = inputData => {
    const mappedData = [];
    _.forEach(inputData, (val, key) => {
      mappedData.push({ name: key, value: val });
    });
    return mappedData.map((i, k) => ({
      ...i,
      color: theme.primaryArray[k % theme.primaryArray.length]
    }));
  };
  return (
    <Card raised sx={{ height: 1, width: 1 }}>
      <CardContent>
        <Grid container justifyContent="space-evenly" spacing={2}>
          <Grid item md={6}>
            <Box height={150}>
              {data.child && (
                <ParentSize>
                  {({ width, height }) => (
                    <PieChart
                      data={getPieData(data.child.t2)}
                      xVar="name"
                      yVar="value"
                      width={width}
                      height={height}
                      legend
                    />
                  )}
                </ParentSize>
              )}
            </Box>
            <Card
              variant="outlined"
              sx={{ p: 1, mt: 3, width: 1, bgcolor: theme.secondary }}
            >
              <Typography variant="dashboardBody">{nl.child[0]}</Typography>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Box height={150}>
              {data.grand_child && (
                <ParentSize>
                  {({ width, height }) => (
                    <PieChart
                      data={getPieData(data.grand_child.t2)}
                      xVar="name"
                      yVar="value"
                      width={width}
                      height={height}
                      legend
                    />
                  )}
                </ParentSize>
              )}
            </Box>
            <Card
              variant="outlined"
              sx={{ p: 1, mt: 3, width: 1, bgcolor: theme.secondary }}
            >
              <Typography variant="dashboardBody">
                {nl.grand_child[0]}
              </Typography>
            </Card>
          </Grid>
          <Grid item md={12}>
            <Box height={180} sx={{ mt: 1 }}>
              <ParentSize>
                {({ width, height }) => (
                  <WordWeb
                    data={data.wordweb}
                    width={width}
                    height={height}
                    colours={theme.sequential3}
                  />
                )}
              </ParentSize>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Card
              variant="outlined"
              sx={{ p: 1, width: 1, bgcolor: theme.secondary }}
            >
              <Typography variant="dashboardBody">{nl.wordweb[0]}</Typography>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

BusinessInsights.propTypes = {
  data: PropTypes.shape(),
  nl: PropTypes.shape(),
  theme: PropTypes.shape()
};

BusinessInsights.defaultProps = {
  data: {},
  nl: {},
  theme: {}
};

export default BusinessInsights;
