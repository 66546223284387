import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import {
  ListItem,
  Collapse,
  Button,
  Box,
  FormHelperText,
  FormControl,
  FormLabel,
  Typography
} from "@mui/material";
import { enterSelectedTerm, deleteTerm } from "../../actions/search";
import { customFilter, customStyles } from "../../utils";
import CustomDate from "../CustomDate";

const Period = props => {
  const {
    options,
    dataDate,
    period,
    periodError,
    story,
    onSelect,
    clearErrors
  } = props;
  const [isCustomDate, setIsCustomDate] = useState(false);
  useEffect(() => {
    if (period && !period.name.period) {
      setIsCustomDate(true);
    }
  }, []);
  const optionList = options
    .map(option =>
      option.keywords.map(kw => ({ name: kw, subsection: option.name }))
    )
    .reduce((array, option) => array.concat(option), []);
  const optionsToUse = [
    ...optionList.map(option => ({
      value: `${option.subsection}--${option.name.period}--${option.name.date}`,
      label: `${option.name.period} ${option.name.date}`
    })),
    {
      value: "custom",
      label: "Choose range on calendar"
    }
  ];
  const selectedValue = period
    ? {
        value: `${period.subsection}--${period.name.period}--${period.name.date}`,
        label: `${period.name.period} ${period.name.date}`
      }
    : "no-value";
  return (
    <ListItem>
      <FormControl
        component="fieldset"
        className="fieldset"
        sx={{ p: 2, mb: 2 }}
        fullWidth
      >
        <FormLabel component="legend" className="legend" sx={{ px: 1 }}>
          <Typography color="textPrimary" fontWeight="bold">
            Time Period
          </Typography>
        </FormLabel>
        <Typography variant="subtitle2" gutterBottom sx={{ mx: 1 }}>
          Please select a time period
        </Typography>
        <Collapse in={!isCustomDate}>
          <ListItem sx={{ px: 1 }}>
            <Select
              styles={customStyles}
              className="smallSelect"
              onChange={e => {
                if (e.value === "custom") {
                  setIsCustomDate(true);
                  onSelect("no-value", period, story);
                } else {
                  onSelect(e.value, period, story);
                }
                clearErrors();
              }}
              options={optionsToUse}
              placeholder="Type or scroll to select a time period"
              value={selectedValue}
              backspaceRemovesValue={false}
              filterOption={customFilter}
            />
          </ListItem>
          <FormHelperText error>{periodError}</FormHelperText>
        </Collapse>
        <Collapse in={isCustomDate} mountOnEnter unmountOnExit>
          <CustomDate
            story={story}
            selectedPeriod={period && !period.name.period ? period : undefined}
            dataDate={dataDate}
            clearErrors={clearErrors}
          />
          <FormHelperText error>{periodError}</FormHelperText>
          <Box textAlign="center" sx={{ mt: 2 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsCustomDate(false);
                onSelect("no-value", period, story);
              }}
            >
              Standard Periods
            </Button>
          </Box>
        </Collapse>
      </FormControl>
    </ListItem>
  );
};

Period.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  dataDate: PropTypes.string,
  period: PropTypes.shape(),
  periodError: PropTypes.string,
  story: PropTypes.string,
  onSelect: PropTypes.func,
  clearErrors: PropTypes.func
};

Period.defaultProps = {
  options: [],
  dataDate: "",
  period: undefined,
  periodError: "",
  story: "",
  onSelect: () => {},
  clearErrors: () => {}
};

const mapDispatchToProps = dispatch => ({
  onSelect: (selectedValue, oldValue, story) => {
    if (oldValue !== undefined) {
      dispatch(deleteTerm(oldValue, story));
    }
    if (selectedValue !== "no-value") {
      const value = selectedValue.split("--");
      dispatch(
        enterSelectedTerm(
          {
            name: { period: value[1], date: value[2] },
            subsection: value[0],
            table: "when",
            story
          },
          story
        )
      );
    }
  }
});

export default connect(null, mapDispatchToProps)(Period);
